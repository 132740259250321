import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  Card,
  VisuallyHidden,
  Button,
  Flex,
  Text,
  Divider,
  Image,
  Loader,
  Icon,
} from "@aws-amplify/ui-react";
import { uploadFilesMetadaAPI, deleteFilesMetadaAPI } from "../../api/upload";
import config from "../../utils/config";

export const S3FileUpload = ({ files, setFiles, level, resource_id, type }) => {
  const BUCKET_NAME = config.BUCKET_NAME;

  //const [key, setKey] = React.useState("");

  const processFile = async ({ file, key }) => {
    // We will get these two from here
    const file_id = uuidv4();
    const file_name = file.name;
    const fileExtension = file.name.split(".").pop();

    const custom_key = `metadata_files/${level}/${resource_id}/${type}/${file_id}/${file_name}`;

    return { file, key: custom_key };
  };

  // Temp Fix
  const getOriginalKey = (otherKey) => {
    for (var key of Object.keys(files["files"])) {
      if (files["files"][key]) {
        if (files["files"][key]["status"]) {
          if (key.includes(otherKey)) return key;
        }
      }
    }
  };

  let [counter, setCounter] = React.useState(0);
  const [fileLimit, setFileLimit] = React.useState(0);

  React.useEffect(() => {
    setFileLimit(counter);
  }, [files, counter]);

  const S3FileUploadLimitReachedComponents = {
    DropZone({ children, displayText, inDropZone, ...rest }) {
      return (
        <Flex
          alignItems="center"
          direction="column"
          padding="medium"
          backgroundColor="rgba(0,0,0,0.05)"
          borderColor="rgba(0,0,0,0.3)"
          borderStyle="dashed"
          {...rest}
        >
          {/* <Text>Drop files here</Text>
          <Divider size="small" label="or" maxWidth="10rem" /> */}
          {children}
        </Flex>
      );
    },
    FilePicker({ onClick }) {
      return (
        // <Button variation="primary" onClick={onClick} disabled>
        //   Browse Files
        // </Button>
        <Text>Only 1 file is allowed to be uploaded</Text>
      );
    },
  };

  const S3FileUploadComponents = {
    DropZone({ children, displayText, inDropZone, ...rest }) {
      return (
        <Flex
          alignItems="center"
          direction="column"
          padding="medium"
          borderColor="rgba(0,0,0,0.3)"
          borderStyle="dashed"
          //backgroundColor={}
          {...rest}
        >
          <Text>Drop files here</Text>
          <Divider size="small" label="or" maxWidth="10rem" />
          {children}
        </Flex>
      );
    },
    FilePicker({ onClick }) {
      return <Button onClick={onClick}>Browse Files</Button>;
    },
  };

  return (
    <>
      <StorageManager
        acceptedFileTypes={[
          // you can list file extensions:
          ".txt",
          ".docx",
          ".doc",
          ".ppt",
          ".pptx",
          ".pdf",
        ]}
        // path={key}
        accessLevel="public"
        autoUpload={false}
        maxFileCount={1}
        maxFileSize={15728640} // 15MB value in bytes
        processFile={processFile}
        onFileRemove={({ key }) => {
          let originalKey = getOriginalKey(key);
          let count = counter;
          count--;
          if (count < 0) count = 0;
          setCounter(count);
          if (originalKey) {
            delete files["files"][originalKey];
            setFiles((prevFiles) => {
              return {
                ...prevFiles,
              };
            });
            //console.log(files);
            // remove from database
            let file_id = originalKey.split("/")[4];
            deleteFilesMetadaAPI(file_id);
          }
        }}
        onUploadSuccess={({ key }) => {
          //console.log(key);
          let count = counter;
          count++;
          setCounter(count);
          setFiles((prevFiles) => {
            return {
              ...prevFiles,
              files: {
                ...prevFiles.files,
                [key]: {
                  status: "success",
                },
              },
            };
          });
          //insert into database
          uploadFilesMetadaAPI(BUCKET_NAME, key);
        }}
        components={
          fileLimit >= 1
            ? S3FileUploadLimitReachedComponents
            : S3FileUploadComponents
        }
      />
      {/* {Object.keys(files).map((key) => {
        return files[key] ? (
          <div>
            {key}: {files[key].status}
          </div>
        ) : null;
      })} */}
    </>
  );
};
