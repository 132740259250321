import React, { useEffect, useRef } from "react";
import "./MeetingAnalysisNew.css";
import { useState } from "react";
import SemiDonutChart from "./SemiDonutChart";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  engagementTimeseriesDataAPI,
  post_annotations,
  get_annotations,
  delete_annotations,
} from "../../api/analyticsPerMeet";
import Chart from "chart.js/auto";
import { getRelativePosition } from "chart.js/helpers";
import Summary from "../../components/meetingComponents/summary/Summary";
import AiInsight from "../../components/meetingComponents/aiInsights/AiInsight";
import KeyMoments from "../../components/meetingComponents/keyMoments/KeyMoments";
import Transcript from "../../components/meetingComponents/transcript/Transcript";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { BsListTask } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { BiSolidRectangle } from "react-icons/bi";

import { v4 as uuidv4 } from "uuid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as CTooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bubble } from "react-chartjs-2";

// import KeyMoments from "../../components/keyMoments/KeyMoments";
// import Summary from "../../components/summary/Summary";
// import TranscriptView from "../../components/transcriptView/TranscriptView";
import {
  analyticsPerMeetTranscriptAPI,
  analyticsPerMeetMetricsAPI,
  analyticsPerMeetAPI,
  analyticsPerMeetFeedbackUpdateAPI,
  videoAPI,
  analyticsPerMeetUpdateTranscriptCommentAPI,
  v2AnalyisAPI,
} from "../../api/analyticsPerMeet";
import { current } from "@reduxjs/toolkit";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  CTooltip,
  Legend,
  annotationPlugin
);

const MeetingAnalysisNew = () => {
  const [selectedTab, setSelectedTab] = useState("Summary");
  const overallCount = 50;

  const [rawTranscriptData, setRawTranscriptData] = useState([]);
  console.log("raw transcript data - ", rawTranscriptData);

  const [generalActionableSuggestion, setGeneralActionableSuggestion] =
    useState([]);
  const [generalOverallSummary, setGeneralOverallSummary] = useState([]);
  const [timelineActionalbeSuggestion, setTimelineActionalbeSuggestion] =
    useState([]);
  const [timelineStrengthImprovement, setTimelineStrengthImprovement] =
    useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [lineChartData, setLineChartData] = useState([]);

  const [new_custommer_engagement, set_new_custommer_engagement] = useState({});

  const secondsToHms = (d) => {
    if (isNaN(parseFloat(d))) {
      return "00:00:00";
    }
    // console.log("ddddddddd", d);
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    let ret = new Date(d * 1000).toISOString().substr(11, 8);

    if (ret.substring(0, 3) === "00:") {
      ret = ret.substring(3);
    }

    return ret;
  };
  let totalScoreToDisplay = parseInt(
    isNaN(totalScore) || totalScore === null || totalScore === undefined
      ? 0
      : totalScore
  );
  const data = {
    labels: ["Score", "Improvement chance"],
    datasets: [
      {
        // data: [(overallCount * 100) / 6, 100 - (overallCount * 100) / 6],
        data: [totalScoreToDisplay, 100 - totalScoreToDisplay],
        backgroundColor: ["#F42D77", "#EEEEEE"],
        borderColor: ["#F42D77", "#EEEEEE"],
        borderWidth: 1,
      },
    ],
  };

  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");

  const user_id = useSelector((e) => {
    return e.user.user_details.user_id;
  });

  const meeting = params.get("meeting");
  let ctx = useRef(null);
  const [presignedUrl, setPresignedUrl] = useState("");
  // const video_id= "test_video_1";
  let video_id = meeting;
  const getPresignedUrl = async () => {
    const tempData = await videoAPI(meeting);
    // // console.log(tempData.data.presigned_url);
    setPresignedUrl(tempData.data.presigned_url);
  };

  const getTranscriptData = async () => {
    setRawTranscriptData([]);
    const tempData = await analyticsPerMeetTranscriptAPI(meeting);
    // // console.log(tempData.data`);
    setRawTranscriptData(
      tempData.data.sort((a, b) => a.start_time - b.start_time)
    );
  };

  const [transformed_data, set_transformed_data] = useState([]);

  console.log("transformed_data in meet", transformed_data);

  useEffect(() => {
    console.log("\n\n\n\n\n\n\n\n\n\n\n came here \n\n\n\n\n\n\n\n\n\n\n\n\n");
    // set_transformed_data([]);

    let transformed_transcriptData = [];
    let idx = -1;

    for (let i = 0; i < rawTranscriptData.length; i++) {
      // console.log("rawTranscriptData[i]", rawTranscriptData[i]);
      if (
        transformed_transcriptData.length === 0 ||
        transformed_transcriptData[transformed_transcriptData.length - 1]
          .speaker_type !== rawTranscriptData[i].speaker_type ||
        rawTranscriptData[i].start_time -
          transformed_transcriptData[transformed_transcriptData.length - 1]
            .end_time >
          5
      ) {
        let temp = { ...rawTranscriptData[i] };
        temp["transcript_list"] = [
          {
            transcript: rawTranscriptData[i].transcript,
            start_time: rawTranscriptData[i].start_time,
            end_time: rawTranscriptData[i].end_time,
          },
        ];

        transformed_transcriptData.push(temp);
      } else {
        transformed_transcriptData[
          transformed_transcriptData.length - 1
        ].transcript += rawTranscriptData[i].transcript;
        transformed_transcriptData[
          transformed_transcriptData.length - 1
        ].end_time = rawTranscriptData[i].end_time;
        transformed_transcriptData[transformed_transcriptData.length - 1][
          "transcript_list"
        ].push({
          transcript: rawTranscriptData[i].transcript,
          start_time: rawTranscriptData[i].start_time,
          end_time: rawTranscriptData[i].end_time,
        });
      }
    }

    // rawTranscriptData.forEach((o) => {
    //   console.log("ptint", o);

    //   if (
    //     idx !== -1 &&
    //     o.speaker_type === transformed_transcriptData[idx].speaker_type &&
    //     o.start_time - transformed_transcriptData[idx].end_time < 5
    //   ) {
    //     transformed_transcriptData[idx].transcript += o.transcript;
    //     transformed_transcriptData[idx].end_time = o.end_time;
    //     transformed_transcriptData[idx]["transcript_list"].push({
    //       transcript: o.transcript,
    //       start_time: o.start_time,
    //       end_time: o.end_time,
    //     });
    //   } else {
    //     transformed_transcriptData.push(o);
    //     idx++;
    //     transformed_transcriptData[idx]["transcript_list"] = [
    //       {
    //         transcript: o.transcript,
    //         start_time: o.start_time,
    //         end_time: o.end_time,
    //       },
    //     ];
    //   }
    // });

    console.log("transformed_transcriptData", transformed_transcriptData);

    set_transformed_data(transformed_transcriptData);
  }, [rawTranscriptData]);

  const fetchLineChartData = async (video_id) => {
    try {
      let response = await engagementTimeseriesDataAPI(video_id);
      console.log("line chart raw data fetched - ", response.data);

      const tsArray = response.data.engagemnts.map((item) => item.ts);
      const scoreArray = response.data.engagemnts.map((item) => item.score);

      setLineChartData({ tsArray: tsArray, scoreArray: scoreArray });
      console.log("line chart data formatted - ", {
        tsArray: tsArray,
        scoreArray: scoreArray,
      });
    } catch (e) {
      console.log("Engagement data fetch failed , error - ", e);
    }
  };

  const [key_moment_timeline_data, set_key_moment_timeline_data] = useState([]);
  const [key_moment, set_key_moment] = useState([]);

  console.log("key_moment ---> ", key_moment);

  const randColor = () => {
    return (
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
        .toUpperCase()
    );
  };

  console.log("key_moment_timeline_data -----> ", key_moment_timeline_data);

  const [topics_covered, set_topics_covered] = useState();
  const [topics_covered_score, set_topics_covered_score] = useState();
  const [playbook_compliance, set_playbook_compliance] = useState();
  const [playbook_compliance_score, set_playbook_compliance_score] = useState();
  const [communication_score, set_communication_score] = useState();
  const [improvements, set_improvements] = useState();
  const [key_questions, set_key_questions] = useState();
  const [assesment_score, set_assesment_score] = useState();

  const [
    custommer_engagement_annotations,
    set_custommer_engagement_annotations,
  ] = useState({
    // box1: {
    //   type: "box",
    //   xMin: 5,
    //   xMax: 6,
    //   yMin: 0,
    //   yMax: 100,
    //   backgroundColor: "rgba(255, 99, 132, 0.25)",
    //   label: { content: "box1", position: "center", display: true },
    // },
  });

  console.log(
    "custommer_engagement_annotations",
    custommer_engagement_annotations
  );

  const fetchV2Analysis = async (video_id) => {
    let topics_covered_response = await v2AnalyisAPI(
      video_id,
      "general",
      "topics_covered",
      user_id
    );
    set_topics_covered(topics_covered_response);

    let topics_covered_score_response = await v2AnalyisAPI(
      video_id,
      "general",
      "topics_covered_score",
      user_id
    );
    set_topics_covered_score(topics_covered_score_response);

    let playbook_compliance_response = await v2AnalyisAPI(
      video_id,
      "general",
      "playbook_compliance",
      user_id
    );
    set_playbook_compliance(playbook_compliance_response);

    let playbook_compliance_score_response = await v2AnalyisAPI(
      video_id,
      "general",
      "playbook_compliance_score",
      user_id
    );
    set_playbook_compliance_score(playbook_compliance_score_response);

    let communication_score_response = await v2AnalyisAPI(
      video_id,
      "general",
      "communication_score",
      user_id
    );
    set_communication_score(communication_score_response);

    let improvements_response = await v2AnalyisAPI(
      video_id,
      "general",
      "improvements",
      user_id
    );
    set_improvements(improvements_response);

    let key_questions_response = await v2AnalyisAPI(
      video_id,
      "general",
      "key_questions",
      user_id
    );
    set_key_questions(key_questions_response);

    let confident_response = await v2AnalyisAPI(
      video_id,
      "timeline",
      "confident",
      user_id
    );

    let hesitant_response = await v2AnalyisAPI(
      video_id,
      "timeline",
      "hesitant",
      user_id
    );

    let enthusiastic_response = await v2AnalyisAPI(
      video_id,
      "timeline",
      "enthusiastic",
      user_id
    );

    let sentiment_response = await v2AnalyisAPI(
      video_id,
      "timeline",
      "sentiment",
      user_id
    );

    let client_satisfaction_response = await v2AnalyisAPI(
      video_id,
      "timeline",
      "client_satisfaction",
      user_id
    );

    console.log("hesitant_response", hesitant_response);

    set_assesment_score({
      confident: confident_response,
      hesitant: hesitant_response,
      enthusiastic: enthusiastic_response,
      sentiment: sentiment_response,
      client_satisfaction: client_satisfaction_response,
    });

    let generalActionableSuggestionResponse = await v2AnalyisAPI(
      video_id,
      "general",
      "action_items",
      user_id
    );
    let generalOverallSummaryResponse = await v2AnalyisAPI(
      video_id,
      "general",
      "section_summary",
      user_id
    );
    // let timelineActionalbeSuggestionResponse = await v2AnalyisAPI(
    //   video_id,
    //   "timeline",
    //   "actionable_suggestions"
    // );
    let timelineStrengthImprovementResponse = await v2AnalyisAPI(
      video_id,
      "timeline",
      "strength_and_improvements",
      user_id
    );
    let totalScoreResponse = await v2AnalyisAPI(
      video_id,
      "general",
      "total_score",
      user_id
    );

    let key_moment_timeline = await v2AnalyisAPI(
      video_id,
      "timeline",
      "actionable_suggestions",
      user_id
    );

    let temp_key_moment_data = key_moment_timeline.data?.sort(
      (a, b) => a.start_time - b.start_time
    );

    temp_key_moment_data.forEach((e) => {
      e.metric_label = e.metric_label
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    });

    let temp_key_moment = [
      {
        label: "Objection",
        value: 1,
        color: "#6A48C9",
        data: [],
      },
      {
        label: "Wow Moment",
        value: 2,
        color: "#F42D77",
        data: [],
      },
      {
        label: "High Point",
        value: 3,
        color: "#ED6D2D",
        data: [],
      },
      {
        label: "Low Point",
        value: 4,
        color: "#67D0C5",
        data: [],
      },
    ];

    console.log(temp_key_moment);

    set_key_moment_timeline_data(temp_key_moment_data);

    temp_key_moment_data.forEach((e) => {
      if (temp_key_moment.findIndex((a) => a.label === e.metric_label) === -1) {
        temp_key_moment.push({
          label: e.metric_label,
          value: temp_key_moment[temp_key_moment.length - 1].value + 1,
          color: randColor(),
          data: [],
        });
      }

      temp_key_moment[
        temp_key_moment.findIndex((a) => a.label === e.metric_label)
      ]["data"].push(e);
    });

    set_key_moment([...temp_key_moment]);

    setGeneralActionableSuggestion(generalActionableSuggestionResponse.data);
    setGeneralOverallSummary(generalOverallSummaryResponse.data);
    // setTimelineActionalbeSuggestion(timelineActionalbeSuggestionResponse.data);
    setTimelineStrengthImprovement(timelineStrengthImprovementResponse.data);
    // setTotalScore(totalScoreResponse.data);

    setTotalScore(Math.round(totalScoreResponse?.data[0]?.metric_value));
    console.log("for Video ID - ", video_id);
    console.log(
      "GeneralActionableSuggestion - ",
      generalActionableSuggestionResponse.data
    );
    console.log("GeneralOverallSummary - ", generalOverallSummaryResponse.data);
    // console.log(
    //   "TimelineActionalbeSuggestion - ",
    //   timelineActionalbeSuggestionResponse.data
    // );
    console.log(
      "TimelineStrengthImprovement - ",
      timelineStrengthImprovementResponse.data
    );
    console.log(" totalScoreResponse.data - ", totalScoreResponse.data);
  };

  const labels = new_custommer_engagement.tsArray;

  const ChartData = {
    labels,
    datasets: [
      {
        label: "Engagement",
        data: new_custommer_engagement.scoreArray,
        borderColor: "rgba(103, 208, 197, 1)",
        backgroundColor: "rgba(103, 208, 197, 1)",
      },
    ],
  };

  const chart = new Chart(ctx, {
    type: "line",
    data: ChartData,
    options: {
      onClick: (e) => {
        const canvasPosition = Chart.helpers.getRelativePosition(e, chart);

        // Substitute the appropriate scale IDs
        const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
        const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
      },
    },
  });

  useEffect(() => {
    // // console.log("use efect called");
    getTranscriptData();
    getPresignedUrl();
    // FOR TESTING -
    fetchV2Analysis(meeting);
    // fetchLineChartData("test_video_1");
    // FOR PRODUCTION -
    fetchLineChartData(meeting);
    // fetchV2Analysis(meeting)
  }, [meeting]);

  const videoRef = useRef(null);

  console.log("video --> ", videoRef?.current?.duration / 60);

  const seekToTimestamp = (timestamp) => {
    if (videoRef.current) {
      console.log("videoRef timestamp", timestamp);
      console.log("videoRef current", videoRef.current);
      videoRef.current.currentTime = timestamp;
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);

  const spanRef = React.useRef();

  const handleClick = (event) => {
    // console.log("event.currentTarget", event.currentTarget);
    setAnchorEl(spanRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorElUp, setAnchorElUp] = useState(null);

  const handleClickUp = (event) => {
    // console.log("event.currentTarget", event.currentTarget);
    setAnchorElUp(spanRef.current);
  };

  const handleCloseUp = () => {
    setAnchorElUp(null);
  };

  const openUp = Boolean(anchorElUp);
  const idUp = openUp ? "simple-popover-2" : undefined;

  const [eng_anno, set_eng_anno] = useState(null);
  const [eng_time, set_eng_time] = useState(null);

  function chunk(str, n) {
    console.log("str", str, n);
    var ret = [];
    var i;
    var len;

    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }

    return ret;
  }

  const [ex, set_ex] = useState();
  const [ey, set_ey] = useState();

  const add_annotate_helper = async () => {
    const id = uuidv4();
    let new_obj = custommer_engagement_annotations;
    new_obj[id] = {
      type: "label",
      xValue: ex,
      yValue: ey,
      backgroundColor: "rgba(245,245,245, 0)",
      content: eng_anno,
      font: {
        size: 12,
      },
    };

    const data_post = await post_annotations_helper(ex, ey, eng_anno);
    console.log("data_post", data_post);

    set_custommer_engagement_annotations({ ...new_obj });

    handleClose();
  };

  const delete_annotations_helper = async (chart_annotations_id) => {
    console.log("chart_annotations_id", chart_annotations_id);
    const data_delete = await delete_annotations(chart_annotations_id);

    let formatted_data_get = { ...custommer_engagement_annotations };
    delete formatted_data_get[chart_annotations_id];

    console.log("formatted_data_get", formatted_data_get);

    set_custommer_engagement_annotations(formatted_data_get);
  };

  const get_annotations_helper = async () => {
    let chart_type = "customer_engagement";
    const data_get = await get_annotations(video_id, chart_type);

    console.log("data_get", data_get);

    let temp_cus = {
      tsArray: [],
      scoreArray: [],
    };

    const lineData = data_get?.data?.filter((e) => {
      return e.is_line_value === 1;
    });

    const annotationData = data_get?.data?.filter((e) => {
      return e.is_line_value === 0 || e.annotation.length > 0;
    });

    console.log("lineData, annotationData", lineData, annotationData);

    const tsArray = lineData.map((item) => item.x);
    const scoreArray = lineData.map((item) => item.y);

    set_new_custommer_engagement({ tsArray: tsArray, scoreArray: scoreArray });

    let formatted_data_get = {};

    // annotation: "price";
    // chart_annotations_id: "3c974938-b18e-44a2-b1ce-80b2192cee49";
    // chart_type: "custommer_engagement";
    // video_id: "ad8186b8-5a7b-4d20-9554-5479e489d0cd";
    // x: 2;
    // y: 53.3965;

    annotationData?.forEach((e, idx) => {
      let temppp = 0;

      if (e.is_line_value === 1) {
        if (temppp + 1 <= 10) {
          temppp += 1;
        } else {
          temppp -= 1;
        }
      }

      // if (idx >= 1) {
      //   console.log("annotationData[idx - 1]", annotationData[idx - 1]);
      //   console.log(
      //     "formatted_data_get[annotationData[idx - 1]",
      //     formatted_data_get[annotationData[idx - 1].chart_annotations_id]
      //   );
      //   const tpp =
      //     formatted_data_get[annotationData[idx - 1].chart_annotations_id];

      //   if (e.x - tpp.xValue < 120) {
      //     temppp = tpp.yValue + 1 <= 10 ? tpp.yValue + 1 : tpp.yValue - 1;
      //   }
      // }

      if (idx >= 1) {
        const tpp =
          formatted_data_get[annotationData[idx - 1].chart_annotations_id];

        if (e.x - tpp.xValue < 120) {
          temppp =
            tpp.yValue - tpp.yAdjust + 15 <= 50
              ? -tpp.yAdjust + 15
              : -tpp.yAdjust - 15;
        }
      }
      console.log("temppppp", temppp);

      const temp = {
        type: "label",
        xValue: e.x,
        // yValue: temppp,
        yValue: e.y,
        backgroundColor: "rgba(245,245,245, 0)",
        content: e.annotation,
        font: {
          size: 8,
        },
        is_line_value: e.is_line_value,

        xAdjust: 100,
        yAdjust: -temppp,
        callout: {
          display: true,
          // side: 1,
          margin: 0,
          borderDash: [5, 5],
          borderColor: "rgba(244, 45, 119, 0.6)",
        },
      };

      formatted_data_get[e.chart_annotations_id] = temp;
    });

    console.log("formatted_data_get", formatted_data_get);

    set_custommer_engagement_annotations(formatted_data_get);
  };

  const post_annotations_helper = async (x, y, annotation) => {
    let chart_type = "customer_engagement";
    let chart_annotations_id = uuidv4();

    const data_post = await post_annotations(
      chart_annotations_id,
      chart_type,
      video_id,
      x,
      y,
      annotation
    );
  };

  useEffect(() => {
    get_annotations_helper();
  }, [video_id]);

  const graph_to_display = () => {
    if (selectedTab === "Summary") {
      return <SemiDonutChart data={data} />;
      // <span className="bold-number-text">57%</span>
      // <span>Overall Score</span>
    } else if (selectedTab === "AI Insights") {
      const options = {
        onClick: function (e) {
          console.log("clicked on chart datapoint, event - ", e);
          // var bar = this.getElementAtEvent(e)[0];
          // var bar = getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
          // var index = bar._index;
          // var datasetIndex = bar._datasetIndex;
          // console.log("bar from chart - ",bar,index)
          // const canvasPosition =Chart.helpers.getRelativePosition(e, chart);
          // const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
          //     const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);

          const canvasPosition = getRelativePosition(e, chart);

          // // Substitute the appropriate scale IDs
          // const dataX = chart.scales.getValueForPixel(canvasPosition.x);
          // const dataY = chart.scales.getValueForPixel(canvasPosition.y);

          // console.log(dataX);
          // console.log(dataY);

          var valueX = null,
            valueY = null;

          for (var scaleName in this.scales) {
            var scale = this.scales[scaleName];
            console.log("scale123", scale);
            if (scale.isHorizontal()) {
              console.log(canvasPosition.x);
              valueX = scale.getValueForPixel(canvasPosition.x);
            } else {
              console.log(canvasPosition.y);
              valueY = scale.getValueForPixel(canvasPosition.y);
            }
          }
          console.log(
            "canvas position x - ",
            canvasPosition.x,
            " vlaue x - ",
            valueX,
            " canvas position y - ",
            canvasPosition.y,
            " value y - ",
            valueY,
            "final timestamp - ",
            lineChartData.tsArray[valueX]
          );

          set_ex(valueX);
          set_ey(valueY);

          // const id = uuidv4();
          // let new_obj = custommer_engagement_annotations;
          // new_obj[id] = {
          //   type: "label",
          //   xValue: valueX,
          //   yValue: valueY,
          //   backgroundColor: "rgba(245,245,245)",
          //   content: ["This is my text"],
          //   font: {
          //     size: 18,
          //   },
          // };

          // set_custommer_engagement_annotations({ ...new_obj });
          // set_openAnnotate(true);
          handleClick();

          // seekToTimestamp(lineChartData.tsArray[valueX]);
          seekToTimestamp(valueX);
        },
        responsive: true,
        // plugins: {
        //   legend: {
        //     position: 'top',
        //   },
        //   title: {
        //     display: true,
        //     text: 'Chart.js Line Chart',
        //   },
        // },
        // }
        layout: {
          autoPadding: false,
          padding: 0,
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                console.log("context.dataset", context.parsed.x);

                console.log("label label", label);

                if (label) {
                  label += ": ";
                }

                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }

                if (context.parsed.x !== null) {
                  label +=
                    " at Time: " + secondsToHms(context.parsed.x.toFixed(2));
                }

                // label += "\n";
                // label += context.raw.title;

                return label;
              },
              title: function () {
                return "";
              },
            },
          },
          legend: {
            display: false,
          },
          annotation: {
            annotations: custommer_engagement_annotations,
          },
        },
        maintainAspectRatio: false,
        cubicInterpolationMode: "monotone",
        scales: {
          y: {
            display: false,
            min: 0,
            // max: 100,
            max: 10,
          },
          x: {
            min: 0,
            // max: 100,
            max: videoRef?.current?.duration,
            font: {
              size: 6,
            },
            type: "linear",
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function (val, index) {
                // Hide every 2nd tick label
                console.log("tick", val, index);
                return secondsToHms(val);
              },
            },
          },
        },

        // pointStyle: "rect",
        pointStyle: false,
      };

      return (
        <div className="semi-donut-wrapper ">
          {/* <p className="chart-heading " onClick={handleClick}>
            Customer Engagement{" "}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div>
                <p>Add annotations</p>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
                <Button>Done</Button>
              </div>
            </Popover>
          </p> */}
          <p className="chart-heading space-between">
            <span>Customer Engagement</span>
            <span
              className="center legend"
              ref={spanRef}
              onClick={() => handleClickUp()}
            >
              {/* + */}
              <BsListTask
                style={{
                  color: "rgba(244, 45, 119, 1)",
                  fontWeight: 600,
                  fontSize: 18,
                  marginRight: "1rem",
                  marginBottom: 0,
                  lineHeight: 0,
                  cursor: "pointer",
                }}
              />
            </span>
            <Popover
              id={idUp}
              open={openUp}
              anchorEl={anchorElUp}
              onClose={handleCloseUp}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="annotions-popup" style={{ width: "300px" }}>
                <span className="pop-head">Annotations</span>
                <br />
                {Object.keys(custommer_engagement_annotations).map((e, i) => {
                  console.log(
                    "custtt",
                    e,
                    i,
                    custommer_engagement_annotations[e]
                  );
                  return (
                    <div className="legend space-between">
                      <span>
                        (x:{" "}
                        {secondsToHms(
                          custommer_engagement_annotations[e]["xValue"]
                        )}
                        , y:{" "}
                        {Math.trunc(
                          custommer_engagement_annotations[e]["yValue"]
                        )}
                        ) &nbsp;
                        {custommer_engagement_annotations[e]["content"]}
                      </span>
                      {}
                      <TiDeleteOutline
                        style={{
                          color: "rgba(244, 45, 119, 1)",
                          fontWeight: 600,
                          fontSize: 16,
                          lineHeight: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => delete_annotations_helper(e)}
                      />
                    </div>
                  );
                })}
                {/* <TextField
                  id="outlined-basic"
                  label="Annotation"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px", marginTop: "1rem" }}
                  onChange={(e) => {
                    // set_eng_anno(e.target.value);
                  }}
                  // value={eng_anno}
                /> */}
                {/* <br />
                <Button
                  variant="contained"
                  sx={{ marginTop: "1rem" }}
                  // onClick={add_annotate_helper}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginTop: "1rem" }}
                  // onClick={add_annotate_helper}
                >
                  Delete
                </Button> */}
              </div>
            </Popover>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="annotions-popup">
                Add annotations
                <br />
                <TextField
                  id="outlined-basic"
                  label="Annotation"
                  variant="outlined"
                  size="small"
                  sx={{ width: "300px", marginTop: "1rem" }}
                  onChange={(e) => {
                    set_eng_anno(e.target.value);
                  }}
                  value={eng_anno}
                />
                {/* <br />
                <TextField
                  id="outlined-basic"
                  label="Timestamp"
                  variant="outlined"
                  size="small"
                  placeholder="hh:mm:ss"
                  sx={{ width: "300px", marginTop: "1rem" }}
                  inputProps={{ maxLength: 8 }}
                  onChange={(e) => {
                    // console.log("e.target.value", e.target.value);

                    // console.log("e.target.value here", e.target.value);
                    set_eng_time(e.target.value);
                  }}
                  value={chunk(
                    eng_time ? eng_time?.replace(/:/g, "") : "",
                    2
                  ).join(":")}
                /> */}
                <br />
                <Button
                  variant="contained"
                  sx={{ marginTop: "1rem" }}
                  onClick={add_annotate_helper}
                >
                  Done
                </Button>
              </div>
            </Popover>
          </p>
          <div className="ok-line-chart ">
            <Line
              height="100%"
              className="line-chart-engagement "
              options={options}
              data={ChartData}
            />
          </div>
        </div>
      );
    } else if (selectedTab === "Key Moments") {
      console.log("video-time ", videoRef?.current?.duration);
      const options = {
        scales: {
          y: {
            display: false,
            min: 0,
            // max: 5,
            max: key_moment.length + 3,
          },
          x: {
            min: 0,
            max: videoRef?.current?.duration,
            font: {
              size: 6,
            },
            type: "linear",
            beginAtZero: 1,
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function (val, index) {
                // Hide every 2nd tick label
                console.log("tick", val, index);
                return secondsToHms(val);
              },
            },
          },
        },
        maintainAspectRatio: false,
        onClick: function (e, element) {
          // console.log(element[0]?.element?.$context?.index);
          // console.log(evt.chart.data.datasets[0].data);
          // console.log(e);
          // var element = this.getElementAtEvent(e);

          // // If you click on at least 1 element ...
          // if (element.length > 0) {
          //   // Logs it
          //   console.log(element[0]);

          //   // Here we get the data linked to the clicked bubble ...
          //   var datasetLabel =
          //     this.config.data.datasets[element[0]._datasetIndex].label;
          //   // data gives you `x`, `y` and `r` values
          //   var data =
          //     this.config.data.datasets[element[0]._datasetIndex].data[
          //       element[0]._index
          //     ];

          //   console.log(data);

          // console.log(
          //   timelineActionalbeSuggestion[element[0].element.$context?.index]
          //     .start_time
          // );
          // seekToTimestamp(
          //   key_moment_timeline_data[element[0].element.$context?.index]
          //     .start_time
          // );
          // }

          const canvasPosition = getRelativePosition(e, chart);

          var valueX = null,
            valueY = null;

          for (var scaleName in this.scales) {
            var scale = this.scales[scaleName];
            console.log("scale123", scale);
            if (scale.isHorizontal()) {
              console.log(canvasPosition.x);
              valueX = scale.getValueForPixel(canvasPosition.x);
            } else {
              console.log(canvasPosition.y);
              valueY = scale.getValueForPixel(canvasPosition.y);
            }
          }

          seekToTimestamp(valueX);
        },
        layout: {
          autoPadding: false,
          padding: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          // datalabels: {
          //   color: "black",
          //   // anchor: "end",
          //   align: "bottom",
          //   // align: (context) => {
          //   //   console.log("context ---> ", context);
          //   //   return context.dataIndex % 2 === 0 ? "top" : "bottom";
          //   // },
          //   offset: 15,
          //   formatter: (val, ctx) => {
          //     // console.log(ctx.chart.data.labels[ctx.dataIndex]);
          //     return ctx.chart.data?.labels[ctx.dataIndex];
          //   },
          // },
          // tooltip: {
          //   callbacks: {
          //     label: function (context) {
          //       let label = context.dataset.label || "";

          //       console.log("context.dataset", context.dataset);

          //       if (label) {
          //         label += ": ";
          //       }
          //       if (context.parsed.x !== null) {
          //         label += secondsToHms(context.parsed.x.toFixed(2) * 60);
          //       }

          //       // label += "\n";
          //       // label += context.raw.title;

          //       return label;
          //     },
          //   },
          // },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                console.log("context.dataset", context.parsed.x);

                console.log("label label", label);

                if (label) {
                  label += ": ";
                }
                if (context.parsed.x !== null) {
                  label += secondsToHms(context.parsed.x.toFixed(2));
                }

                // label += "\n";
                // label += context.raw.title;

                return label;
              },
              title: function () {
                return "";
              },
            },
          },
        },
      };

      const data = {
        // labels: key_moment_timeline_data.map((a) => {
        //   return a.metric_label;
        // }),
        // datasets: [
        //   {
        //     label: "Key Moments",
        //     data: key_moment_timeline_data.map((e) => {
        //       console.log("bublle --> ", (e.start_time + e.end_time) / 2 / 60);
        //       return {
        //         title: e.metric_label,
        //         y: 3,
        //         x: (e.start_time + e.end_time) / 2 / 60,
        //         r: 10,
        //       };
        //     }),
        //     backgroundColor: "rgba(53, 162, 235, 0.5)",
        //   },
        // ],

        datasets: key_moment.map((point) => {
          let ret_point = {};

          ret_point.label = point.label;
          ret_point.data = point.data.map((p) => {
            console.log("point", p);
            return {
              y: point.value,
              x: (p.start_time + p.end_time) / 2,
              r: Math.max(4, (p.end_time - p.start_time) / 10),
            };
          });

          ret_point.backgroundColor = point.color;
          ret_point.borderColor = point.color;

          return ret_point;
        }),
      };

      console.log("blah blah data", data);

      return (
        <div className="semi-donut-wrapper ">
          <div className="chart-heading space-between">
            Key Moments
            <HtmlTooltip
              title={
                <React.Fragment>
                  {key_moment.map((opa) => {
                    return (
                      <div className="left">
                        <BsCircleFill color={opa.color} />
                        &nbsp;&nbsp;
                        {opa.label}
                      </div>
                    );
                  })}
                </React.Fragment>
              }
            >
              <Button
                sx={{ margin: 0, padding: 0, color: "rgba(150, 154, 255, 1)" }}
              >
                <AiOutlineInfoCircle />
              </Button>
            </HtmlTooltip>
          </div>
          <div className="ok-line-chart ">
            <Bubble
              options={options}
              data={data}
              // plugins={[ChartDataLabels]}
              height="100%"
              className="analysis-chart-v2"
            />
          </div>
        </div>
      );
    } else if (selectedTab === "Transcript") {
      let rally_labels = [];
      let rally_data = [
        {
          label: "SDR",
          data: [],
          borderColor: "#2A2FA1",
          backgroundColor: "#2A2FA1",
        },
        {
          label: "Client",
          data: [],
          borderColor: "#F42D77",
          backgroundColor: "#F42D77",
        },
      ];

      console.log("rawTranscriptData", rawTranscriptData);

      transformed_data.forEach((e) => {
        if (rally_labels[rally_labels.length - 1] === e.start_time) {
          rally_labels.push((e.start_time + e.end_time) / 2);
          rally_labels.push(e.end_time);

          console.log("start1 ", e.start_time);
          console.log("middle1 ", (e.start_time + e.end_time) / 2);
          console.log("end1 ", e.end_time);

          if (e.speaker_type === "client") {
            rally_data[0].data.push(null);
            rally_data[0].data.push(null);

            rally_data[1][rally_data[1].length - 1] = 1;
            rally_data[1].data.push(1);
            rally_data[1].data.push(1);
          } else {
            rally_data[0][rally_data[0].length - 1] = 2;
            rally_data[0].data.push(2);
            rally_data[0].data.push(2);

            rally_data[1].data.push(null);
            rally_data[1].data.push(null);
          }
        } else {
          rally_labels.push(e.start_time);
          rally_labels.push((e.start_time + e.end_time) / 2);
          rally_labels.push(e.end_time);

          console.log("start ", e.start_time);
          console.log("middle ", (e.start_time + e.end_time) / 2);
          console.log("end ", e.end_time);

          if (e.speaker_type === "client") {
            rally_data[0].data.push(null);
            rally_data[0].data.push(null);
            rally_data[0].data.push(null);

            rally_data[1].data.push(1);
            rally_data[1].data.push(1);
            rally_data[1].data.push(1);
          } else {
            rally_data[0].data.push(2);
            rally_data[0].data.push(2);
            rally_data[0].data.push(2);

            rally_data[1].data.push(null);
            rally_data[1].data.push(null);
            rally_data[1].data.push(null);
          }
        }
      });

      const data = {
        labels: rally_labels,
        datasets: rally_data,
      };

      console.log("find data ---> ", data);

      const options = {
        scales: {
          y: {
            display: false,
            min: 0,
            max: 3,
          },
          x: {
            min: 0,
            ticks: {
              // maxTicksLimit: 7,
              minRotation: 0,
              maxRotation: 0,
            },
            max: videoRef?.current?.duration,
            type: "linear",
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function (val, index) {
                // Hide every 2nd tick label
                console.log("tick", val, index);
                return secondsToHms(val);
              },
            },
          },
        },
        layout: {
          autoPadding: false,
          padding: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          // tooltip: {
          //   callbacks: {
          //     label: function (context) {
          //       let label = context.dataset.label || "";

          //       console.log(label);

          //       // if (label) {
          //       //   label += ": ";
          //       // }
          //       // if (context.parsed.x !== null) {
          //       //   label += context.parsed.x.toFixed(2);
          //       // }

          //       // label += "\n";
          //       // label += context.raw.title;

          //       return label;
          //     },
          //   },
          // },
          tooltip: {
            // enabled: false,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                console.log("context.dataset", context.parsed.x);

                console.log("label label", label);

                if (label) {
                  label += ": ";
                }
                if (context.parsed.x !== null) {
                  label += secondsToHms(context.parsed.x.toFixed(2));
                }

                // label += "\n";
                // label += context.raw.title;

                return label;
              },
              title: function () {
                return "";
              },
            },
          },
          // annotation: {
          //   annotations: {
          //     box1: {
          //       type: "box",
          //       xMin: 30,
          //       xMax: 50,
          //       yMin: 0,
          //       yMax: 3,
          //       backgroundColor: "rgba(255, 99, 132, 0.25)",
          //       label: { content: "box1", position: "center", display: true },
          //     },
          //     box2: {
          //       type: "box",
          //       xMin: 70,
          //       xMax: 190,
          //       yMin: 0,
          //       yMax: 3,
          //       backgroundColor: "rgba(255, 99, 132, 0.25)",
          //       label: { content: "box2", position: "start", display: true },
          //     },
          //   },
          // },
        },
        onClick: function (e) {
          // console.log(element[0]?.index);

          // console.log(rally_labels[element[0]?.index]);

          // console.log(evt.chart.data.datasets[0].data);

          // console.log(e);
          // var element = this.getElementAtEvent(e);

          // // If you click on at least 1 element ...
          // if (element.length > 0) {
          //   // Logs it
          //   console.log(element[0]);

          //   // Here we get the data linked to the clicked bubble ...
          //   var datasetLabel =
          //     this.config.data.datasets[element[0]._datasetIndex].label;
          //   // data gives you `x`, `y` and `r` values
          //   var data =
          //     this.config.data.datasets[element[0]._datasetIndex].data[
          //       element[0]._index
          //     ];

          //   console.log(data);

          // console.log(
          //   timelineActionalbeSuggestion[element[0].element.$context?.index]
          //     .start_time
          // );

          // seekToTimestamp(rally_labels[element[0]?.index]);

          // }

          const canvasPosition = getRelativePosition(e, chart);

          var valueX = null,
            valueY = null;

          for (var scaleName in this.scales) {
            var scale = this.scales[scaleName];
            if (scale.isHorizontal()) {
              console.log(canvasPosition.x);
              valueX = scale.getValueForPixel(canvasPosition.x);
            } else {
              console.log(canvasPosition.y);
              valueY = scale.getValueForPixel(canvasPosition.y);
            }
          }
          console.log(
            "canvas position x - ",
            canvasPosition.x,
            " vlaue x - ",
            valueX,
            " canvas position y - ",
            canvasPosition.y,
            " value y - ",
            valueY,
            "final timestamp - ",
            lineChartData.tsArray[valueX]
          );

          set_ex(valueX);
          set_ey(valueY);
          seekToTimestamp(valueX);
        },
        maintainAspectRatio: false,
        // pointStyle: "rect",
        pointStyle: false,
      };

      return (
        <div className="semi-donut-wrapper ">
          <p className="chart-heading space-between">
            {/* <span>Rally Chart</span> */}
            <span></span>
            <span className="center legend">
              <BiSolidRectangle color="#2A2FA1" /> &nbsp;SDR &nbsp; &nbsp;
              <BiSolidRectangle color="#F42D77" /> &nbsp;Client
            </span>
          </p>
          <div className="ok-line-chart">
            <Line
              options={options}
              data={data}
              height="100%"
              className="analysis-chart-v2"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="meeting-analysis-new-root ">
      <div className="meeting-analysis-top-row ">
        <div className="meeting-analysis-video-box ">
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            src={presignedUrl}
            className=""
            controls={true}
          />
        </div>
        <div className="meeting-analysis-main-chart">{graph_to_display()}</div>
      </div>
      <div className="meeting-analysis-bottom-box ">
        <div className="meeting-analysis-tabs-row">
          {/* <div
            className={
              selectedTab === "Strengths and improvements"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Strengths and improvements");
            }}
          >
            Strengths and improvements
          </div> */}
          <div
            className={
              selectedTab === "Summary"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Summary");
            }}
          >
            Summary
          </div>
          <div
            className={
              selectedTab === "AI Insights"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("AI Insights");
            }}
          >
            AI Insights
          </div>
          {/* <div
            className={
              selectedTab === "Analysis metrics"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Analysis metrics");
            }}
          >
            Analysis metrics
          </div> */}
          <div
            className={
              selectedTab === "Key Moments"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Key Moments");
            }}
          >
            Key Moments
          </div>
          {/* <div
            className={
              selectedTab === "Feedback"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Feedback");
            }}
          >
            Feedback
          </div> */}
          <div
            className={
              selectedTab === "Transcript"
                ? "meeting-analysis-tab-selected"
                : "meeting-analysis-tab"
            }
            onClick={() => {
              setSelectedTab("Transcript");
            }}
          >
            Transcript
          </div>
        </div>
        {/* <div className="meeting-analysis-content"> */}
        {selectedTab === "Summary" && (
          <div className="meeting-analysis-content">
            <Summary
              generalActionableSuggestion={generalActionableSuggestion}
              generalOverallSummary={generalOverallSummary}
              totalScore={totalScore}
              seekToTimestamp={seekToTimestamp}
              meeting={meeting}
            />
          </div>
        )}
        {/* {selectedTab === "Strengths and improvements" && (
            <Summary
              generalOverallSummary={generalOverallSummary}
              timelineStrengthImprovement={timelineStrengthImprovement}
              seekToTimestamp={seekToTimestamp}
            />
          )} */}
        {selectedTab === "AI Insights" && (
          <div className="meeting-analysis-content-borderless">
            <AiInsight
              // generalActionableSuggestion={generalActionableSuggestion}
              // timelineActionalbeSuggestion={timelineActionalbeSuggestion}
              // topicsCovered={topicsCovered}
              // playbookCompliance={playbookCompliance}

              topics_covered={topics_covered}
              topics_covered_score={topics_covered_score}
              playbook_compliance={playbook_compliance}
              playbook_compliance_score={playbook_compliance_score}
              communication_score={communication_score}
              improvements={improvements}
              key_questions={key_questions}
              assesment_score={assesment_score}
              seekToTimestamp={seekToTimestamp}
            />
          </div>
        )}
        {selectedTab === "Key Moments" && (
          <div className="meeting-analysis-content">
            <KeyMoments
              key_moment_timeline_data={key_moment_timeline_data}
              seekToTimestamp={seekToTimestamp}
            />
          </div>
        )}
        {selectedTab === "Transcript" && (
          <div className="meeting-analysis-content-borderless">
            <Transcript
              transcriptData={transformed_data}
              seekToTimestamp={seekToTimestamp}
            />
          </div>
        )}
        {/* <button onClick={() => seekToTimestamp(30)}>Seek to 30 seconds</button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default MeetingAnalysisNew;
