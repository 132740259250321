import React from "react";
import "./Profile.css";
import ASSETS from "../../assets/Assets";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const profile_pic = ASSETS.person1;
const email = "kjsadk@kdjhf.com";
const role = "manager";
const name = "johnd deere";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#575454",
  backgroundColor: "#F8DE0F",
  "&:hover": {
    backgroundColor: "#8BDFEC",
    color: "#D37C9C",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "1rem",
}));

export default function Profile() {
  return (
    <div className="profile-wrapper">
      <img className="settings-profile-picture" src={profile_pic} />
      <div className="settings-profile-content-wrapper">
        <p className="settings-profile-name">{name}</p>
        <p className="settings-profile-email">{email}</p>
        {/* <p className="settings-profile-password-heading">Change password</p>
        <p>Enter current password</p>
        <OutlinedInput
          placeholder=""
          size="small"
          sx={{ width: "300px", marginBottom: "1rem" }}
        />
        <p>Enter new password</p>
        <OutlinedInput
          placeholder=""
          size="small"
          sx={{ width: "300px", marginBottom: "1rem" }}
        />
        <br />
        <ColorButton variant="contained">Save</ColorButton> */}
      </div>
    </div>
  );
}
