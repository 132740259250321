import React, { useEffect, useState } from "react";
import "./Transcript.css";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GiPlainCircle } from "react-icons/gi";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Transcript({ transcriptData, seekToTimestamp }) {
  console.log("transcriptData ---> ", transcriptData);

  // const [transformed_data, set_transformed_data] = useState([]);

  // console.log("transformed_data", transformed_data);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    let ret = new Date(d * 1000).toISOString().substr(11, 8);

    if (ret.substring(0, 3) === "00:") {
      ret = ret.substring(3);
    }

    return ret;
  };

  // useEffect(() => {
  //   console.log("\n\n\n\n\n\n\n\n\n\n\n came here \n\n\n\n\n\n\n\n\n\n\n\n\n");
  //   // set_transformed_data([]);

  //   let transformed_transcriptData = [];
  //   let idx = -1;

  //   for (let i = 0; i < transcriptData.length; i++) {
  //     // console.log("transcriptData[i]", transcriptData[i]);
  //     if (
  //       transformed_transcriptData.length === 0 ||
  //       transformed_transcriptData[transformed_transcriptData.length - 1]
  //         .speaker_type !== transcriptData[i].speaker_type ||
  //       transcriptData[i].start_time -
  //         transformed_transcriptData[transformed_transcriptData.length - 1]
  //           .end_time >
  //         5
  //     ) {
  //       let temp = { ...transcriptData[i] };
  //       temp["transcript_list"] = [
  //         {
  //           transcript: transcriptData[i].transcript,
  //           start_time: transcriptData[i].start_time,
  //           end_time: transcriptData[i].end_time,
  //         },
  //       ];

  //       transformed_transcriptData.push(temp);
  //     } else {
  //       transformed_transcriptData[
  //         transformed_transcriptData.length - 1
  //       ].transcript += transcriptData[i].transcript;
  //       transformed_transcriptData[
  //         transformed_transcriptData.length - 1
  //       ].end_time = transcriptData[i].end_time;
  //       transformed_transcriptData[transformed_transcriptData.length - 1][
  //         "transcript_list"
  //       ].push({
  //         transcript: transcriptData[i].transcript,
  //         start_time: transcriptData[i].start_time,
  //         end_time: transcriptData[i].end_time,
  //       });
  //     }
  //   }

  //   // transcriptData.forEach((o) => {
  //   //   console.log("ptint", o);

  //   //   if (
  //   //     idx !== -1 &&
  //   //     o.speaker_type === transformed_transcriptData[idx].speaker_type &&
  //   //     o.start_time - transformed_transcriptData[idx].end_time < 5
  //   //   ) {
  //   //     transformed_transcriptData[idx].transcript += o.transcript;
  //   //     transformed_transcriptData[idx].end_time = o.end_time;
  //   //     transformed_transcriptData[idx]["transcript_list"].push({
  //   //       transcript: o.transcript,
  //   //       start_time: o.start_time,
  //   //       end_time: o.end_time,
  //   //     });
  //   //   } else {
  //   //     transformed_transcriptData.push(o);
  //   //     idx++;
  //   //     transformed_transcriptData[idx]["transcript_list"] = [
  //   //       {
  //   //         transcript: o.transcript,
  //   //         start_time: o.start_time,
  //   //         end_time: o.end_time,
  //   //       },
  //   //     ];
  //   //   }
  //   // });

  //   console.log("transformed_transcriptData", transformed_transcriptData);

  //   set_transformed_data(transformed_transcriptData);
  // }, [transcriptData]);

  const sales_person_ratio = transcriptData.reduce(
    (a, o) =>
      (o.speaker_type === "sales-person" ? o.end_time - o.start_time : 0) + a,
    0
  );

  const client_ratio = transcriptData.reduce(
    (a, o) => (o.speaker_type === "client" ? o.end_time - o.start_time : 0) + a,
    0
  );

  // const pause_ratio =
  //   transcriptData[transcriptData.length - 1]?.end_time -
  //   transcriptData[0]?.start_time;

  console.log("sales_person_ratio", sales_person_ratio);
  console.log(client_ratio);
  // console.log(pause_ratio);

  const pie_options = {
    plugins: {
      legend: {
        display: false,
        // position: "bottom",
        // align: "start",

        // labels: {
        //   usePointStyle: true,
        //   // boxWidth: 20,
        // },
      },
      datalabels: {
        color: "white",
        // // anchor: "end",
        // align: "bottom",
        // // align: (context) => {
        // //   console.log("context ---> ", context);
        // //   return context.dataIndex % 2 === 0 ? "top" : "bottom";
        // // },
        // offset: 15,
        font: {
          weight: 700,
          size: "20px",
        },

        formatter: (val, ctx) => {
          // console.log(ctx.chart.data.labels[ctx.dataIndex]);
          return (
            Math.round((val * 100) / (sales_person_ratio + client_ratio)) + "%"
            // Math.trunc((val * 100) / (sales_person_ratio + client_ratio)) + "%"
          );
        },
      },
    },
  };

  const data = {
    labels: ["Sales Person", "Client"],
    datasets: [
      {
        label: "Talk ratio",
        data: [sales_person_ratio, client_ratio],
        backgroundColor: ["rgba(42, 47, 161, 1)", "rgba(244, 45, 119, 1)"],
        borderColor: ["rgba(42, 47, 161, 1)", "rgba(244, 45, 119, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="transcript-wrapper-v2">
      <div className="transcript-transcript-wrapper-v2 width70">
        <span className="heading-text">Transcript</span>
        <div />

        {transcriptData.map((data) => (
          <div className="moment-wrapper-v2">
            <div
              className="transcript-title"
              onClick={() => {
                seekToTimestamp(data.start_time);
              }}
            >
              {/* <AiOutlinePlayCircle /> */}

              <span
                style={{
                  color:
                    data.speaker_type === "sales-person"
                      ? "rgba(42, 47, 161, 1)"
                      : "rgba(244, 45, 119, 1)",
                }}
              >
                {data.speaker_type === "sales-person"
                  ? "Sales person"
                  : "Client"}
              </span>

              <span className="key-moment-time-v2">
                {secondsToHms(data.start_time) +
                  " to " +
                  secondsToHms(data.end_time)}
              </span>
            </div>
            {data.transcript_list.map((e) => {
              return (
                <span
                  className="transcript-block"
                  onClick={() => {
                    seekToTimestamp(e.start_time);
                  }}
                >
                  {e.transcript}
                </span>
              );
            })}
            {/* <span className="">{data.transcript}</span> */}
          </div>
        ))}
      </div>
      <div className="transcript-transcript-wrapper-v2 width30">
        <span className="heading-text">Talk Ratio</span>
        <div className="center" style={{ padding: "1rem" }}>
          <Pie data={data} options={pie_options} plugins={[ChartDataLabels]} />
        </div>
        <div className="transcript-leged">
          <GiPlainCircle style={{ color: "rgba(42, 47, 161, 1)" }} />
          <span className="transcript-legend-title">SDR</span>
          <GiPlainCircle
            style={{ color: "rgba(244, 45, 119, 1)", marginLeft: "1rem" }}
          />
          <span className="transcript-legend-title">Client</span>
        </div>
      </div>
    </div>
  );
}
