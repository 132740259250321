//PRODUCTION
const baseURL = "https://81evbvzj6c.execute-api.ap-northeast-1.amazonaws.com";
// DEV
// const baseURL = "https://ktd63rmz82.execute-api.ap-south-1.amazonaws.com";
// const baseURL = "http://127.0.0.1:8080";

const util = {
  baseURL,
};

export default util;
