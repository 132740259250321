import { SelectField } from "@aws-amplify/ui-react";

export default function SelectAnalysisLevel({
  analysisLevel,
  handleChange,
  defaultOrUser,
}) {
  // return (
  //   <div>
  //     <FormControl sx={{ m: 1, minWidth: 200 }}>
  //       <InputLabel id="demo-simple-select-helper-label">
  //         Analysis Level
  //       </InputLabel>
  //       <Select
  //         labelId="demo-simple-select-helper-label"
  //         id="demo-simple-select-helper"
  //         value={analysisLevel}
  //         //defaultValue="user"
  //         defaultValue={defaultOrUser}
  //         label="Analysis Level"
  //         onChange={handleChange}
  //       >
  //         {defaultOrUser === "user" ? (
  //           <MenuItem value="user">User</MenuItem>
  //         ) : (
  //           <MenuItem value="default">Default</MenuItem>
  //         )}
  //         <MenuItem value="video">Video</MenuItem>
  //       </Select>
  //     </FormControl>
  //   </div>
  // );

  return (
    <div>
      <SelectField
        label="Analysis Level"
        defaultValue={defaultOrUser}
        value={analysisLevel}
        onChange={handleChange}
        size="medium"
      >
        {defaultOrUser === "user" ? (
          <option value="user">Default</option>
        ) : (
          <option value="default">Default</option>
        )}
        <option value="video">Video</option>
      </SelectField>
    </div>
  );
}
