import React from "react";
import "./ProfileMenu.css";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import {
  HelpCenterRounded,
  HelpOutline,
  LanguageOutlined,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { defaultProfilePictureURL, getInitials } from "../../../utils/helperFunctions";
import { updateLanguageAPI } from "../../../api/Users";
import { updateLanguage } from "../../../store/user/userEffect";
import { Link as RouterLink } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const Link = React.forwardRef(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function ProfileMenu({ anchorEl, handleClose, open }) {
  const dispatch = useDispatch();

  const { signOut } = useAuthenticator((context) => [context.user]);

  const handleLanguageChange = async (event) => {
    let langValue = "en";
    if (!event.target.checked) {
      langValue = "ja";
    }
    const result = await updateLanguageAPI(user.user_id, langValue);
    // setLanguage(event.target.value);
    dispatch(updateLanguage(langValue));
  };
  const profilePicData = useSelector((e) => {
    return e.user.profile_pictures;
  });
  const user_id = useSelector((e) => {
    return e.user.user_details.user_id;
  });


  var profilePicURL = defaultProfilePictureURL
  if (profilePicData && typeof profilePicData === 'object' && user_id in profilePicData) {
    var profilePicURL = profilePicData[user_id];
    console.log('Profile Pic:', profilePicURL);
  } else {
    console.log('The profile pic object is not valid or does not have the user_id property.');
  }
  const language = useSelector((store) => {
    // console.log(store);
    if (
      store.user.user_details === undefined ||
      store.user.user_details === null
    ) {
      return null;
    }
    return store.user.user_details.language;
  });
  const navigate = useNavigate()
  const user = useSelector((store) => {
    // console.log(store);
    if (store.user?.user_details === undefined) {
      return null;
    }
    return store.user.user_details;
  });
  const logoutHelperFunc = () => {
    signOut();
    handleClose();
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        background: "rgba(0, 0, 0, 0.1)",
      }}
    >
      <List sx={{ minWidth: "20rem" }}>
        <ListItemButton sx={{ width: "100%" }} divider
        onClick={()=>{
          navigate("/profile")
        }}
        >
          {user?.profile_image && (
            <ListItemAvatar>
              <Avatar src={user.profile_image} alt="profile image" />
            </ListItemAvatar>
          )}
          {!user?.profile_image && (
            <ListItemAvatar>
              {/* <Avatar>{getInitials(user?.name)}</Avatar> */}
              <div className="circle-image-small-div">

              <img className="circle-image-small" src={profilePicURL}/>
              </div>
            </ListItemAvatar>
          )}
          <ListItemText>View Profile</ListItemText>
        </ListItemButton>
        {/* <ListItem divider>
          <ListItemText primary="English Language" />
          <Switch
            edge="end"
            onChange={handleLanguageChange}
            checked={language === "en"}
            inputProps={{
              "aria-labelledby": "switch-list-label-wifi",
            }}
          />
        </ListItem>
        <ListItemButton component={Link} to="/asd" divider>
          {
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#969AFF" }}>
                <HelpOutline />
              </Avatar>
            </ListItemAvatar>
          }
          <ListItemText primary={"Help"} />
        </ListItemButton> */}
        <ListItemButton onClick={logoutHelperFunc}>
          {
            <ListItemAvatar>
              <Avatar sx={{ color: "#969AFF", bgcolor: "white" }}>
                <MdLogout />
              </Avatar>
            </ListItemAvatar>
          }
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </List>
    </Popover>
  );
}

export default ProfileMenu;
