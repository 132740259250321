import React, { useEffect, useState } from "react";
import "./Analytics.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import ASSETS from "../../assets/Assets";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import AnalyticsPerPerson from "./AnalyticsPerPerson";
import { getUsers } from "../../api/Users";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

export default function Analytics() {
  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");
  // console.log(user)
  const [data1, setData1] = useState([]);
  // let org_id = "test_org";
  const org_id = useSelector((store) => {
    console.log(store);
    if (store.user.user_details === null) {
      return "";
    }
    return store.user.user_details.organisation_id;
  });

  const fetchUsers = async (user_id) => {
    let response = await getUsers(org_id);
    // console.log(
    //   "fetched users for organization - ",
    //   org_id,
    //   " - ",
    //   response.data
    // );
    setData1(response.data);
  };

  useEffect(() => {
    fetchUsers(user);
  }, [user, org_id]);

  const [search, setSearch] = useState("");
  // console.log(search);

  const profilePicData = useSelector((e) => {
    return e.user.profile_pictures;
  });

  return (
    <div className="analysis-wrapper">
      <p className="member-analysis-heading">Memberwise analysis</p>
      <div className="analysis-content ">
        <div className="analysis-list-wrapper">
          <p
            className="member-list-name active"
            style={{ width: "100%", marginBottom: "0.5rem" }}
          >
            Members
          </p>
          <OutlinedInput
            placeholder="Search"
            size="small"
            sx={{ marginBottom: "1rem", width: "calc(100% - 10px)" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div className="member-list ">
            <List sx={{ width: "100%" }}>
              {data1
                .filter((e) => {
                  return e.name.includes(search);
                })
                .map((e) => {
                  const link = "/analytics?user=" + e.user_id;
                  return (
                    <ListItemButton
                      component={Link}
                      to={link}
                      selected={user === e.user_id}
                      key={e.user_id}
                      divider
                      className="member-list-item"
                    >
                    { profilePicData !== undefined && profilePicData.hasOwnProperty(e?.user_id) ? 
                      <div className="profile-pic-small-div user-list-spacing">
                        {profilePicData !== undefined && <img className="profile-pic-small-div" src={profilePicData[e?.user_id]}/>}
                      </div> :
                      <ListItemAvatar>
                        <Avatar
                          src={e.profile_image || ASSETS.user}
                          alt="profile image"
                        />
                        {/* <img src={profilePicData[e.user_id]}/> */}
                      </ListItemAvatar> 
                      
                    }
                      <ListItemText
                        primary={e.name}
                        className="member-list-name"
                      />
                      {user === e.user_id ? (
                        <AiOutlineRight className="member-name-arrow" />
                      ) : null}
                    </ListItemButton>
                  );
                })}
            </List>
          </div>
        </div>
        <AnalyticsPerPerson data1={data1} />
      </div>
    </div>
  );
}
