import React, { useRef, useEffect, useState } from "react";

import { Link, Routes, Route, useLocation, Navigate } from "react-router-dom";

import { redirect } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Provider } from "react-redux";
import store from "./store/rootStore";
import {
  fetchUserDetails,
  fetchUserDetailsFromDb,
  updateLanguage,
} from "./store/user/userEffect";
import { fetchProfilePicturesSuccess } from "./store/user/userAction";

import { generateProfilePictureUrls } from "./api/Users";

import Dashboard from "./screens/dashboard/Dashboard";
import Analytics from "./screens/analytics/Analytics";
import Settings from "./screens/settings/Settings";
import ASSETS from "./assets/Assets";
import Meeting from "./screens/analytics/Meeting";

import { FiHome, FiBarChart2, FiSettings, FiUpload } from "react-icons/fi";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiOutlineDown } from "react-icons/ai";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdLogout } from "react-icons/md";

import { Amplify, Auth, Storage } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  Heading,
  useTheme,
  TextField,
  Button as AwsButton,
  Alert,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./utils/aws-exports";

import { verifyOrganisationAPI } from "./api/verifyOrganisationCode";

import { getUserDetails, updateLanguageAPI } from "./api/Users";

import "./App.css";
import Topbar from "./components/UI/topbar/Topbar";
import { ThreeDots } from "react-loader-spinner";
import Upload from "./screens/upload/Upload";
import Profile from "./screens/profile/Profile";

Amplify.configure(awsExports);
Storage.configure({
  customPrefix: {
    public: "",
  },
});

const SalesApp = ({ logOut }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const org_id = useSelector((store) => {
    console.log(store);
    return store.user.user_details.organisation_id;
  });

  const fetchOrgProfilePicture = async (org_id) => {
    let response = await generateProfilePictureUrls("organisation", org_id);
    console.log("profile picture of org fetch results - ", response.data);
    dispatch(fetchProfilePicturesSuccess(response.data));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(user);
    dispatch(
      fetchUserDetails({ attributes: user.attributes, username: user.username })
    );
    // dispatch(fetchUserDetails(JSON.parse(user.toString())));
    // dispatch(fetchUserDetails({ ...user }));
  }, []);

  useEffect(() => {
    if (org_id) {
      fetchOrgProfilePicture(org_id);
    }
  }, [org_id]);

  const params = new URLSearchParams(window.location.search);
  // const user = params.get("user");
  const meeting = params.get("meeting");
  // console.log(params);
  const location = useLocation();
  // console.log(location.pathname);

  let width = window.innerWidth;
  // console.log(width)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="app">
      <div className="sidebar">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={ASSETS.iby_logo} className="iby-logo" alt="logo" />
        </Link>

        {/* <Link to="/" style={{ textDecoration: "none" }} className="link">
          <div
            className={
              location.pathname !== "/" ? "sidebar-tab" : "sidebar-tab active"
            }
          >
            <FiHome className="sidebar-icon" />
            Dashboard
          </div>
        </Link> */}
        {/* later convert to /analytics */}
        <Link to="/analytics" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/analytics"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            <FiBarChart2 className="sidebar-icon" />
            Analytics
          </div>
        </Link>
        <Link to="/settings" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/settings"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            <FiSettings className="sidebar-icon" />
            Settings
          </div>
        </Link>
        <Link to="/upload" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/upload"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            <FiUpload className="sidebar-icon" />
            Upload
          </div>
        </Link>
      </div>
      <div className="main-content-wrapper">
        <Topbar />
        <Routes>
          <Route path="/" element={<Navigate to="/analytics" />} />
          <Route
            path="/analytics"
            element={meeting === null ? <Analytics /> : <Meeting />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
};

function AuthApp() {
  const { user, route, authStatus, toSignUp, toSignIn, signOut } =
    useAuthenticator((context) => [context.user, context.authStatus]);
  const [loading, setLoading] = useState(true);
  const [authComponent, setAuthComponent] = useState("signIn");
  const [
    showOrganisationVerificationComponent,
    setShowOrganisationVerificationComponent,
  ] = useState(false);
  const [verificationCode, setVerifcationCode] = useState("");
  const [wrongVerificationCodeAlert, setWrongVerificationCodeAlert] =
    useState(false);
  const [awsVerifyButtonState, setAWSVerifyButtonState] = useState(false);
  const [organisation, setOrganisation] = useState("");

  const toVerfiyOrganisationCode = () => {
    setShowOrganisationVerificationComponent(true);
    setAuthComponent("signUp");
  };

  const verifyOrganisation = async () => {
    setAWSVerifyButtonState(true);
    //Calling the api
    const response = await verifyOrganisationAPI(verificationCode);
    if ("organisation_id" in response.data) {
      setOrganisation(response.data);
      setShowOrganisationVerificationComponent(false);
      setVerifcationCode("");
      toSignIn();
      toSignUp();
    } else {
      setWrongVerificationCodeAlert(true);
    }
    setAWSVerifyButtonState(false);
  };

  const toSignInComponent = () => {
    setShowOrganisationVerificationComponent(false);
    setAuthComponent("signIn");
    toSignIn();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (authStatus === "authenticated") {
      getUserDetails(user.username).then((result) => {
        dispatch(fetchUserDetailsFromDb(result.data[0]));
        setLoading(false);
      });
    }
  }, [authStatus]);

  const logOut = () => {
    signOut();
  };

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign In
          </Heading>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign Up
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signUp: {
      "custom:organisation_id": {
        order: 1,
        name: "custom:organisation_id",
        type: "custom:organisation_id",
      },
      "custom:organisation_name": {
        order: 2,
        name: "custom:organisation_name",
        type: "custom:organisation_name",
      },
      "custom:role": {
        order: 3,
        name: "custom:role",
        type: "custom:role",
      },
      name: {
        order: 4,
      },
      email: {
        order: 5,
      },
      password: {
        order: 6,
      },
      confirm_password: {
        order: 7,
        required: true,
      },
    },
  };

  useEffect(() => {
    // Setting Role
    let input = document.querySelector('input[name="custom:role"]');
    if (input) {
      input.setAttribute("value", organisation.role);
      input.setAttribute("readonly", true);
    }

    //Setting Organisation Name
    input = document.querySelector('input[name="custom:organisation_name"]');
    if (input) {
      input.setAttribute("value", organisation.organisation_name);
      input.setAttribute("readonly", true);
    }

    // Setting Organisation Id
    input = document.querySelector('input[name="custom:organisation_id"]');
    if (input) {
      input.setAttribute("value", organisation.organisation_id);
      input.setAttribute("readonly", true);
    }
  }, [organisation]);

  return (
    <>
      {authStatus === "authenticated" && !loading ? (
        <SalesApp logOut={logOut} />
      ) : authStatus === "configuring" ||
        (authStatus === "authenticated" && loading) ? (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : authStatus === "unauthenticated" ? (
        <div className="auth-wrapper">
          <div className="auth-promotion center">
            <img
              className="auth-promotion-app-logo"
              src={ASSETS.logo}
              alt="app-logo"
            />
            {authComponent === "signIn" ? (
              <div className="auth-promotion-auth-page">
                <div>If you don’t have an account.</div>
                <div>
                  You can{" "}
                  <span onClick={toVerfiyOrganisationCode}>Register Here</span>
                </div>
              </div>
            ) : (
              <div className="auth-promotion-auth-page">
                <div>If you already have an account.</div>
                <div>
                  You can <span onClick={toSignInComponent}>Login Here</span>
                </div>
              </div>
            )}
            <img
              className="auth-promotion-logo"
              src={ASSETS.promotion_logo}
              alt="promotion-logo"
            />
          </div>
          {showOrganisationVerificationComponent ? (
            <div className="auth-form-verify-org center">
              <div className="auth-form-verify-org-field-wrapper">
                <Heading level={3}>Organisation Code</Heading>
              </div>
              <div className="auth-form-verify-org-field-wrapper">
                <TextField
                  size="large"
                  placeholder="Enter Organisation Code"
                  errorMessage="There is an error"
                  isRequired
                  value={verificationCode}
                  onChange={(event) => {
                    setVerifcationCode(event.target.value);
                  }}
                />
              </div>
              {wrongVerificationCodeAlert ? (
                <Alert
                  className="auth-form-verify-alert"
                  variation="error"
                  isDismissible={true}
                  onDismiss={() => {
                    setWrongVerificationCodeAlert(false);
                  }}
                  hasIcon={true}
                  heading="Wrong Verification Code"
                >
                  Please enter the correct verification code
                </Alert>
              ) : (
                <div></div>
              )}
              <AwsButton
                isDisabled={awsVerifyButtonState}
                isFullWidth={true}
                isLoading={awsVerifyButtonState}
                variation="primary"
                size="medium"
                loadingText="Verifying"
                onClick={verifyOrganisation}
                ariaLabel=""
              >
                Verify
              </AwsButton>
            </div>
          ) : (
            <Authenticator
              className="auth-form"
              signUpAttributes={["name", "email"]}
              loginMechanisms={["email"]}
              formFields={formFields}
              components={components}
              //services={services}
            />
          )}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

const App = () => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <AuthApp />
      </Provider>
    </Authenticator.Provider>
  );
};

export default App;
