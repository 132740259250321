import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

// import selectionReducer from "./reducers/selection";
// import articlesReducer from "./reducers/articles";
// import todosReducer, { I_TodoState } from "./reducers/todos";
import userReducer from "./user/userReducer";

export const history = createBrowserHistory();

// combineReducers will be handled internally by configureStore
const rootReducer = (history) => ({
  // articles: articlesReducer,
  // selection: selectionReducer,
  // todos: todosReducer,
  user: userReducer,
});

const preloadedState = {};
const store = configureStore({
  reducer: rootReducer(history),
  preloadedState,
});

export default store;
