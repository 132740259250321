import React from "react";
import { Doughnut } from "react-chartjs-2";

import "./SemiDonutChart.css";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  annotationPlugin
);

export default function SemiDonutChart({ data }) {
  // data = parseInt(isNaN(topicsScore) ? 0 : topicsScore)
  console.log(data);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          label1: {
            type: "label",
            xValue: 0,
            yValue: 0,
            // backgroundColor: "rgba(245,245,245)",
            content: [data?.datasets[0]?.data[0] + "%"],
            font: [
              {
                size: "40px",
                weight: 400,
              },
            ],
            color: "rgba(73, 72, 79, 1)",
            yAdjust: 30,
            // position: "bottom",
          },
        },
      },
    },
    rotation: -90,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    cutout: "90%",
    // borderJoinStyle: "round",
    borderRadius: 50,
  };

  return (
    <div className="semi-donut-wrapper">
      <p className="chart-heading">Overall Score</p>
      <div className="ok-donut-chart ">
        <Doughnut
          height="100%"
          options={options}
          data={data}
          className=" semi-donut-chart "
        />
      </div>
      <p className="chart-bottom">Overall Score</p>
    </div>
  );
}
