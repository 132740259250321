import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { Dna } from "react-loader-spinner";
import {
  uploadFilesMetadaAPI,
  deleteFilesMetadaAPI,
  readFilesMetadaAPI,
} from "../../api/upload";
import "./S3FileUpload.css";
import config from "../../utils/config";

export const UploadFile = ({ heading, type, userId }) => {
  const [files, setFiles] = React.useState([]);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [counter, setCounter] = React.useState(0);

  //const [key, setKey] = React.useState("");
  const BUCKET_NAME = config.BUCKET_NAME;

  const processFile = async ({ file, key }) => {
    // We will get these two from here
    const file_id = uuidv4();
    const level = "user";
    const resource_id = userId;
    const file_name = file.name;
    const fileExtension = file.name.split(".").pop();

    const custom_key = `metadata_files/${level}/${resource_id}/${type}/${file_id}/${file_name}`;

    return { file, key: custom_key };
  };

  React.useEffect(() => {
    setLoadingStatus(true);
    // call the api
    readFilesMetadaAPI(userId, type, "user")
      .then((res) => {
        // console.log("Read File Metadata result");
        // console.log(res);
        // update files
        setFiles(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong !");
      });

    setLoadingStatus(false);
  }, [counter]);

  const S3FileUploadComponents = () => {
    return (
      <StorageManager
        acceptedFileTypes={[
          // you can list file extensions:
          ".txt",
          ".docx",
          ".doc",
          ".ppt",
          ".pptx",
          ".pdf",
        ]}
        // path={key}
        accessLevel="public"
        autoUpload={false}
        maxFileCount={1}
        maxFileSize={15728640} // 15MB value in bytes
        processFile={processFile}
        onUploadSuccess={({ key }) => {
          setLoadingStatus(true);
          //insert into database
          uploadFilesMetadaAPI(BUCKET_NAME, key)
            .then(() => {
              let count = counter;
              count++;
              setCounter(count);
            })
            .catch((err) => {
              console.log(err);
              alert("Something went wrong");
            });
        }}
      />
    );
  };

  const FileListComponent = ({ files }) => {
    const deleteFile = (file) => {
      setLoadingStatus(true);

      deleteFilesMetadaAPI(file.file_id)
        .then(() => {
          //Will not work for multiple files
          setFiles([]);
          let count = counter;
          count++;
          setCounter(count);
        })
        .catch((err) => {
          console.log(err);
          alert("Something went wrong");
        });
    };

    let fileList = files.map((file) => {
      return (
        <div className="file-list-component">
          <span className="file-list-component-name">{file.file_name}</span>
          <div
            className="file-list-component-delete-button"
            onClick={() => deleteFile(file)}
          >
            Delete
          </div>
        </div>
      );
    });
    return <div>{fileList}</div>;
  };

  const Loader = () => {
    return (
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    );
  };

  return (
    <div>
      <h5>{heading}</h5>
      {loadingStatus ? (
        <Loader />
      ) : files.length > 0 ? (
        <FileListComponent files={files} />
      ) : (
        <S3FileUploadComponents />
      )}
    </div>
  );
};
