import axios from "axios";
import util from "./util";

export const analyticsPerMeetTranscriptAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/transcript";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
};

export const analyticsPerMeetMetricsAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/metrics";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const fetchMetricDetailsAPI = async (video_id, metric_type) => {
  const baseURL = util.baseURL;
  const path = "/metrics_details";
  const parameters = "?video_id=" + video_id + "&metric_type=" + metric_type;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const analyticsPerMeetAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/meeting_metadata";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const analyticsPerMeetFeedbackUpdateAPI = async (video_id, feedback) => {
  const baseURL = util.baseURL;
  const path = "/update_feedback";

  // console.log(video_id);
  // console.log(feedback);

  const params = {
    video_id: video_id,
    feedback: feedback.text,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const videoAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/video";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const analyticsPerMeetUpdateTranscriptCommentAPI = async (
  video_id,
  transcript_id,
  comment
) => {
  const baseURL = util.baseURL;
  const path = "/transcript";

  const params = {
    video_id: video_id,
    transcript_id: transcript_id,
    comment: comment,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const v2AnalyisAPI = async (
  video_id,
  metric_type,
  metric_title,
  user_id
) => {
  const baseURL = util.baseURL;
  const path = "/v2_analysis";

  console.log("user_id in api", user_id);

  const parameters = "?user_id=" + user_id;

  // console.log(video_id);
  // console.log(feedback);

  const params = {
    video_id: video_id,
    metric_type: metric_type,
    metric_title: metric_title,
  };

  const data = await axios.post(`${baseURL + path + parameters}`, params);

  return data;
};

export const engagementTimeseriesDataAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/engagements";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const post_annotations = async (
  chart_annotations_id,
  chart_type,
  video_id,
  x,
  y,
  annotation
) => {
  const baseURL = util.baseURL;
  const path = "/chart_annotations";

  const method = "post";
  const params = {
    method: method,
    chart_annotations_id: chart_annotations_id,
    chart_type: chart_type,
    video_id: video_id,
    x: x,
    y: y,
    annotation: annotation,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const get_annotations = async (video_id, chart_type) => {
  const baseURL = util.baseURL;
  const path = "/chart_annotations";

  const method = "get";
  const params = {
    method: method,
    video_id: video_id,
    chart_type: chart_type,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const delete_annotations = async (chart_annotations_id) => {
  const baseURL = util.baseURL;
  const path = "/chart_annotations";

  const method = "delete";
  const params = {
    method: method,
    chart_annotations_id: chart_annotations_id,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const setMetricLabelAPI = async (metric_id, set) => {
  const baseURL = util.baseURL;
  const path = "/set_metric_label";
  const parameters = "?metric_id=" + metric_id + "&set=" + set;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const getActionItemsAPI = async (video_id) => {
  const baseURL = util.baseURL;
  const path = "/actionable_items";
  const parameters = "?video_id=" + video_id;

  const data = await axios.get(`${baseURL + path + parameters}`);
  // console.log(data)

  return data;
};

export const createActionItemAPI = async (
  video_id,
  item_label,
  item_value,
  item_type,
  item_status
) => {
  const baseURL = util.baseURL;
  const path = "/actionable_items";

  const params = {
    video_id: video_id,
    item_label: item_label,
    item_value: item_value,
    item_type: item_type,
    item_status: item_status,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};
