import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart({ data }) {
  const secondsToHms = (d) => {
    // console.log(d)
    d = Number(d);
    // console.log(d)
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return secondsToHms(tooltipItem[0].label);
          },
          label: function (tooltipItem) {
            console.log(tooltipItem);
            return tooltipItem.dataset.label + " : " +tooltipItem.formattedValue;
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
      },
      x: {
        min: 0,
        ticks: {
          callback: function (label, index, labels) {
            return secondsToHms(data.labels[label]);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return <Line options={options} data={data} />;
}
