import axios from "axios";
import util from "./util";

export const verifyOrganisationAPI = async (verification_code) => {
  const baseURL = util.baseURL;
  const path = "/verify_organisation_code";
  const parameters = "?verification_code=" + verification_code;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};
