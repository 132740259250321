import React from "react";
import "./DashboardGraph.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function DashboardGraph({ dataset, labels }) {
  const [clientName, setClientName] = React.useState("");

  const handleChange = (event) => {
    setClientName(event.target.value);
  };
  return (
    <div className="dashboard-graph-wrapper">
      <div className="dashboard-graph-header">
        <div className="dashboard-graph-heading">{dataset[0].label}</div>
      </div>
      <div className="dashboard-graph-body">
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
              },
            },
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: false,
                text: "Client Rapport Graph",
                position: "top",
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  stepSize: 10,
                  beginAtZero: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: dataset,
          }}
        />
      </div>
    </div>
  );
}

export default DashboardGraph;
