import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export default function BubbleChart({ data }) {

  var r = new RegExp(
    "[A-Za-z0-9_]+|" + // ASCII letters (no accents)
      "[\u3040-\u309F]+|" + // Hiragana
      "[\u30A0-\u30FF]+|" + // Katakana
      "[\u4E00-\u9FFF\uF900-\uFAFF\u3400-\u4DBF]", // Single CJK ideographs
    "g"
  );

  const secondsToHms = (d) => {
    // console.log(d)
    d = Number(d);
    // console.log(d)
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            var text = "";
            tooltipItem.forEach((element) => {
              var tempText = element.raw.text;
              if (text === "") {
                text = text + tempText;
              } else {
                text = text + "\n" + tempText;
              }
            });
            console.log(tooltipItem);
            return (
              "Time: " +
              secondsToHms(tooltipItem[0].raw["x"]) +
              "\n" +
              "Text: \n" +
              text
            );
          },
          label: function (tooltipItem) {
            return null;
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max:
          Math.max(
            ...data.map((e) => {
              console.log(e);
              return e.r;
            })
          ) * 3,
        display: false,
      },
      x: {
        min: 0,
        ticks: {
          callback: function (label, index, labels) {
            // console.log(label)
            return secondsToHms(label);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const PlotData = {
    datasets: [
      {
        label: "Mirroring",
        data: data,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Bubble options={options} data={PlotData} />;
}
