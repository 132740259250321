import React, { useEffect, useState } from "react";
import { UploadFile } from "./S3FileUpload";
import { useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";

const Files = () => {
  const raw = useSelector((store) => {
    return store.user.user_details;
  });

  const userId = raw !== null ? raw.user_id : null;

  const [showComponet, setShowComponent] = useState(false);

  useEffect(() => {
    if (userId) setShowComponent(true);
  }, [userId]);

  const Loader = () => {
    return (
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    );
  };

  return userId ? (
    <div>
      <h3>Upload Files</h3>
      <UploadFile
        heading="Buyer Persona"
        type="buyer_persona"
        userId={userId}
      />
      <UploadFile
        heading="Product Description"
        type="product_description"
        userId={userId}
      />
      <UploadFile heading="Playbook" type="playbook" userId={userId} />
      <UploadFile heading="Agenda" type="agenda" userId={userId} />
    </div>
  ) : (
    <Loader />
  );
};

export default Files;
