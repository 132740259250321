import axios from "axios";
import util from "./util";

export const readBenchmarkAPI = async (organisation_id) => {
  const baseURL = util.baseURL;
  const path = "/benchmark";
  const parameters = "?organisation_id=" + organisation_id;

  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
};

export const updateBenchmarkAPI = async (
  organisation_id,
  metric_type,
  score,
  reset = false
) => {

  const baseURL = util.baseURL;
  const path = "/benchmark";

  const params = {
    organisation_id: organisation_id,
    metric_type: metric_type,
    score: score,
    reset: reset,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};
