import React, { useState } from "react";
import "./Topbar.css";
import ASSETS from "../../../assets/Assets";
import { useAuthenticator } from "@aws-amplify/ui-react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguageAPI } from "../../../api/Users";
import { updateLanguage } from "../../../store/user/userEffect";
import { AiOutlineDown } from "react-icons/ai";
import {
  Avatar,
  Badge,
  FormControl,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { MdLogout } from "react-icons/md";
import { defaultProfilePictureURL, getInitials } from "../../../utils/helperFunctions";
import ProfileMenu from "./ProfileMenu";

function Topbar() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const dispatch = useDispatch();
  const user_name = useSelector((store) => {
    // console.log(store);
    if (store.user?.user?.attributes === undefined) {
      return null;
    }
    return store.user.user.attributes.name;
  });

  const user_details = useSelector((store) => store.user.user_details);
  const user_id = useSelector((e) => {
    return e.user.user_details.user_id;
  });
  const profilePicData = useSelector((e) => {
    return e.user.profile_pictures;
  });

  var profilePicURL = defaultProfilePictureURL
  if (profilePicData && typeof profilePicData === 'object' && user_id in profilePicData) {
    var profilePicURL = profilePicData[user_id];
    console.log('Profile Pic:', profilePicURL);
  } else {
    console.log('The object is not valid or does not have the user_id property.');
  }


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const language = useSelector((store) => {
    // console.log(store);
    if (
      store.user.user_details === undefined ||
      store.user.user_details === null
    ) {
      return null;
    }
    return store.user.user_details.language;
  });

  const handleChange = async (event) => {
    const result = await updateLanguageAPI(user.username, event.target.value);
    // setLanguage(event.target.value);
    dispatch(updateLanguage(event.target.value));
  };

  const logoutHelperFunc = () => {
    handleClose();
    signOut();
  };
  return (
    <div className="topbar">
      <img src={ASSETS.logo} className="topbar-logo" alt="logo" />
      <div className="topbar-items">
        {/* <div className="topbar-coins-wrapper">
          <img src={ASSETS.coin} alt="coin" />
          <div className="topbar-coin-amount">200</div>
        </div>
        <div className="topbar-bell-wrapper">
          <Badge color="error" variant="dot">
            <NotificationsIcon />
          </Badge>
        </div> */}
        <div className="topbar-profile-wrapper">
          <div
            className="topbar-profile-wrapper"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            {/* <div className="topbar-profile-photo"> */}
              {/* <img
                src={
                  user_details?.profile_image ||
                  `https://ui-avatars.com/api/?name=${user_name}&background=random`
                }
                alt="profile pic"
              /> */}
              {/* <Avatar
                sx={{
                  bgcolor: "#f5f5ff",
                  color: "black",
                  width: "2rem",
                  height: "2rem",
                }}
                variant="square"
              >
                {getInitials(user_name)}
              </Avatar> */}
              <div className="circle-image-small-div">

              <img className="circle-image-small" src={profilePicURL}/>
              </div>
            {/* </div> */}
            <p className="topbar-profile-name">{user_name}</p>
            <AiOutlineDown />
          </div>
          <ProfileMenu
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
          />
        </div>
      </div>
    </div>
  );
}

export default Topbar;
