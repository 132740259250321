const config = {
  REDIRECT_URL: "http://localhost:3000/settings",
  ZOOM_CLIENT_ID: "VnD5rtHdRIUuqMkZwRiHw",
  GOOGLE_CLIENT_ID:
    "267345110430-fsf0fgkuamn186bc4uoam0qkn3mpcthk.apps.googleusercontent.com",
  GOOGLE_SCOPE: "https://www.googleapis.com/auth/drive.readonly",
  MICROSOFT_CLIENT_ID: "92dfccd0-a235-4188-bfd3-3f11343359d4",
  MICROSOFT_SCOPE: "files.read",
  SALESFORCE_CLIENT_ID:
    "3MVG9fe4g9fhX0E4NA.O.642nWtXPUtJ.04OqCIkQOcDQ_kpHGLlzvBBlAE9MjifWe9nsS9IdyQNyM3hNusY5",
  BUCKET_NAME: "sales-meeting-files-bucket",
};

export default config;
