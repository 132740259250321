// import {
// 	getAvgFaceEmotions,
// 	getAvgFaceEmotionsGroupByParticipant,
// 	getFaceEmotionsInBetween,
// } from "../../api/FaceEmotions";

import {
  fetchUserDetailsSuccess,
  fetchUserDetailsDBSuccess,
  updateLanguageSuccess, fetchProfilePicturesSuccess
} from "./userAction";

export const fetchUserDetails = (details) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsSuccess(details));
  };
};

export const fetchUserDetailsFromDb = (details) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsDBSuccess(details));
  };
};

export const updateLanguage = (details) => {
  return (dispatch) => {
    dispatch(updateLanguageSuccess(details));
  };
};

export const fetchProfilePicture = (details) => {
  return (dispatch)=>{
    dispatch(fetchProfilePicturesSuccess(details))
  }
}
