import axios from "axios";
import util from "./util";

export const uploadvideoApi = async (
  org_id,
  user_id,
  title,
  start_time,
  videoId,
  meetingId,
  buyerPersonaAnalysisLevel,
  productDescriptionAnalysisLevel,
  playbookAnalysisLevel,
  agendaAnalysisLevel
) => {
  const baseURL = util.baseURL;
  const path = "/video";

  const params = {
    org_id: org_id,
    user_id: user_id,
    title: title,
    start_time: start_time,
    video_id: videoId,
    meeting_id: meetingId,
    buyer_persona_level: buyerPersonaAnalysisLevel,
    playbook_level: playbookAnalysisLevel,
    product_description_level: productDescriptionAnalysisLevel,
    agenda_level: agendaAnalysisLevel,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const uploadFilesMetadaAPI = async (bucket_name, key) => {
  console.log(key.split("/")[4]);
  const baseURL = util.baseURL;
  const path = "/file_metadata";

  const params = {
    bucket_name: bucket_name,
    key: key,
  };

  const data = await axios.post(`${baseURL + path}`, params);
  console.log(data);

  return data;
};

export const deleteFilesMetadaAPI = async (file_id) => {
  const baseURL = util.baseURL;
  const path = "/file_metadata";

  const parameters = "?file_id=" + file_id;

  const data = await axios.delete(`${baseURL + path + parameters}`);

  return data;
};

export const readFilesMetadaAPI = async (userId, fileType, resourceType) => {
  const baseURL = util.baseURL;
  const path = "/file_metadata";

  const parameters =
    "?resource_id=" +
    userId +
    "&file_type=" +
    fileType +
    "&resource_type=" +
    resourceType;

  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
};
