export const reformatBenchmarks = (benchmarks) => {
  let len = benchmarks.length;
  let map = new Map();
  for (let i = 0; i < len; i++) {
    let currObj = benchmarks[i];
    map.set(currObj.metric_type, currObj.score);
  }
  console.log("reformatted benchmarks - ", map);
  return map;
};

export const getVideoScores = (videoScores, benchmarks) => {
  let map = new Map();
  let len = videoScores.length;
  console.log("video data for generating videoscore - ", videoScores);
  let benchmarksMap = reformatBenchmarks(benchmarks);
  for (let i = 0; i < len; i++) {
    let currObj = videoScores[i];
    let id = currObj.video_id;
    if (currObj.score < benchmarksMap.get(currObj.metric_type)) {
      if (!map.has(id)) {
        map.set(id, 0);
      }
    } else {
      if (map.has(id)) {
        map.set(id, map.get(id) + 1);
      } else {
        map.set(id, 1);
      }
    }
  }
  // console.log("video score count map - ", map);
  //COUNTING THE VARIOUS CATEGORIES FROM THE VIDOE MAP
  return map;
};

export const categorizeWithBenchmark = (videoScores) => {
  let size = videoScores.size;
  let cat1 = 0;
  let cat2 = 0;
  let cat3 = 0;
  videoScores.forEach((value, key) => {
    if (value < 2) {
      cat1++;
    } else if (value <= 4) {
      cat2++;
    } else {
      cat3++;
    }
  });

  return [cat1, cat2, cat3];
};

export const getInitials = function (string) {
  if (!string) return "";
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const defaultProfilePictureURL = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png";
export const loadingProfilePictureURL = "https://miro.medium.com/v2/resize:fit:882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif";