import React, { useEffect } from "react";
import "./AiInsight.css";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  LineElement,
  Filler,
  PointElement,
  Legend,
  RadialLinearScale,
} from "chart.js";

import checkboxTrue from "../../../assets/checkboxTrue.svg";
import checkboxFalse from "../../../assets/checkboxFalse.svg";
import listPoint from "../../../assets/listPoint.svg";

import annotationPlugin from "chartjs-plugin-annotation";

import { Doughnut, Radar } from "react-chartjs-2";
import { setMetricLabelAPI } from "../../../api/analyticsPerMeet";
ChartJS.register(
  ArcElement,
  Tooltip,

  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  annotationPlugin
);

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function AiInsight({
  topics_covered,
  topics_covered_score,
  playbook_compliance,
  playbook_compliance_score,
  communication_score,
  improvements,
  key_questions,
  assesment_score,
}) {
  console.log("input props - ", {
    topics_covered,
    topics_covered_score,
    playbook_compliance,
    playbook_compliance_score,
    communication_score,
    improvements,
    key_questions,
    assesment_score,
  });

  const [communicationScore, setCommunicationScore] = useState(0);
  const [playbookScore, setPlaybookScore] = useState(0);
  const [topicsScore, setTopicsScore] = useState(0);
  const [communicationList, setCommunicationList] = useState([]);
  const [playbookList, setPlaybookList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [keyQuestionsList, setKeyQuestionsList] = useState([]);
  const [radarAssessmentData, setRadarAssessmentData] = useState([]);

  useEffect(() => {
    setCommunicationList(improvements?.data);
    setPlaybookScore(playbook_compliance_score?.data[0]?.metric_value);
    setCommunicationScore(communication_score?.data[0]?.metric_value);
    setTopicsScore(topics_covered_score?.data[0]?.metric_value);
    setTopicsList(topics_covered?.data);
    setPlaybookList(playbook_compliance?.data);
    // setRadarAssessmentData(
    //   normalizeArray(generateAssessmentRadarData(assesment_score))
    // );
    setRadarAssessmentData(generateAssessmentRadarData(assesment_score));
    setKeyQuestionsList(key_questions?.data);
  }, [
    topics_covered,
    topics_covered_score,
    playbook_compliance,
    playbook_compliance_score,
    communication_score,
    improvements,
    key_questions,
    assesment_score,
  ]);

  console.log("all final data in state variable", {
    communicationScore,
    playbookScore,
    communicationList,
    topicsScore,
    playbookList,
    topicsList,
    radarAssessmentData,
    keyQuestionsList,
  });

  const generateAssessmentRadarData = (assesment_score) => {
    let client_satisfaction = assesment_score?.client_satisfaction.data;
    let hesitant = assesment_score?.hesitant?.data;
    let sentiment = assesment_score?.sentiment?.data;
    let enthusiastic = assesment_score?.enthusiastic?.data;
    let confident = assesment_score?.confident?.data;

    // console.log("average of client satisfaction - ",getAverageOfAssessmentData(client_satisfaction))
    // console.log("average of hesitant - ",getAverageOfAssessmentData(hesitant))
    // console.log("average of sentiment - ",getAverageOfAssessmentData(sentiment))
    // console.log("average of enthusiastic - ",getAverageOfAssessmentData(enthusiastic))
    // console.log("average of confident - ",getAverageOfAssessmentData(confident))

    let res = [
      getAverageOfAssessmentData(client_satisfaction),
      getAverageOfAssessmentData(hesitant),
      getAverageOfAssessmentData(sentiment),
      getAverageOfAssessmentData(enthusiastic),
      getAverageOfAssessmentData(confident),
    ];

    console.log("final radar data - ", res);

    return res;
  };

  const setMetricLabel = async (metric_id, set) => {
    let response = await setMetricLabelAPI(metric_id, set);
    console.log("response on calling set metric api - ", response);
  };

  function normalizeArray(inputArray) {
    // Find the minimum and maximum values in the input array
    const min = Math.min(...inputArray);
    const max = Math.max(...inputArray);

    // Initialize an empty array to store the normalized values
    const normalizedArray = [];

    // Normalize each element in the input array and store in the new array
    for (const num of inputArray) {
      const normalizedValue = (num - min) / (max - min);
      normalizedArray.push(normalizedValue);
    }
    console.log("normalized Array - ", normalizedArray);
    return normalizedArray;
  }

  const getAverageOfAssessmentData = (data) => {
    if (data === null || data === undefined || data.length === 0) return 0;
    // console.log("data for getting average - ",data)
    let sum = 0;

    // Loop through the objects and calculate the sum of metric_value
    for (const obj of data) {
      sum += parseFloat(obj.metric_value);
    }

    // Calculate the average
    const average = sum / data.length;

    // console.log('Average metric_value:', average);

    // multiplied with 100 so range is 0-100
    return average * 100;
  };

  const dataRadar = {
    labels: [
      "Client Satisfaction",
      "Hesitant",
      "Sentiment",
      "Enthusiasm",
      "Confidence",
    ],

    // labels:false,
    datasets: [
      {
        // data: [2, 9, 3, 5, 2],
        data: radarAssessmentData,
        // backgroundColor: "rgba(255, 99, 132, 0.2)",
        // borderColor: 'rgba(255, 99, 132, 1)',
        // borderWidth: 0,
      },
    ],
  };

  const options2 = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        pointLabels: {
          // font: 20,
        },
        ticks: {
          display: false,
        },
        min: 0,
        max: 100,
      },
    },
    backgroundColor: "rgba(42, 47, 161, 0.2)",
    borderColor: "rgba(42, 47, 161, 1)",
    borderWidth: 2,
    pointBackgroundColor: "rgba(42, 47, 161, 0.2)",
    pointBorderColor: "#FFFFFF",
    responsive: true,
    maintainAspectRatio: false,
  };

  const getDonutChartData = (value) => {
    let data = {
      // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      labels: false,
      datasets: [
        {
          // label: '# of Votes',
          data: [value, 100 - value],
          backgroundColor: ["rgba(42, 47, 161, 1)", "rgba(216, 216, 216, 1)"],
          borderColor: ["rgba(42, 47, 161, 0)", "rgba(216, 216, 216, 0)"],
          borderWidth: 0,
        },
      ],
    };

    return data;
  };

  const [keyQuestions, setKeyQuestions] = useState([
    "sameple questions one",
    "where is the business proof of this claim",
    "what is the deadlie for the statement",
    "sameple questions one",
    "where is the business proof of this claim",
    "what is the deadlie for the statement",
    "sameple questions one",
    "where is the business proof of this claim",
    "what is the deadlie for the statement",
  ]);

  const getDonutChartOptions = (value) => {
    let res = {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        annotation: {
          annotations: {
            label1: {
              type: "label",
              xValue: 0,
              yValue: 0,
              // backgroundColor: "rgba(245,245,245)",
              content: value + "%",
              // [data?.datasets[0]?.data[0] + "%"]
              font: [
                {
                  size: "40px",
                  weight: 400,
                },
              ],
              color: "rgba(73, 72, 79, 1)",
              // yAdjust: 30,
              // position: "bottom",
            },
          },
        },
      },
      // rotation: -90,
      // circumference: 180,
      responsive: true,
      maintainAspectRatio: false,
      cutout: "88%",
      // borderJoinStyle: "round",
      borderRadius: 50,
    };

    return res;
  };

  const [next_steps, set_next_steps] = useState([
    {
      id: 1,
      content: "Eiusmod duis dolor anim elit veniam anim.",
      created_by: "ai",
      status: true,
    },
    {
      id: 2,
      content:
        "Et ullamco pariatur cillum minim velit cupidatat ut nulla est laboris mollit elit.",
      created_by: "ai",
      status: false,
    },
    {
      id: 3,
      content:
        "Labore adipisicing Lorem dolor eiusmod exercitation sint in nostrud labore officia reprehenderit id velit eiusmod.",
      created_by: "ai",
      status: true,
    },
    {
      id: 4,
      content: "Incididunt est culpa consequat irure cillum.",
      created_by: "ai",
      status: false,
    },
    {
      id: 5,
      content: "Duis Lorem mollit consequat esse dolore tempor.",
      created_by: "ai",
      status: true,
    },
  ]);
  return (
    <div className="aiinsight-wrapper">
      <div className="aiinsight-aiinsight-wrapper-scrolless">
        <span className="heading-text">Topics Covered</span>
        <div className="circle-heading-div">
          <Doughnut
            data={getDonutChartData(isNaN(topicsScore) ? 0 : topicsScore)}
            options={getDonutChartOptions(parseInt(isNaN(topicsScore) ? 0 : topicsScore))}
          />
          {/* <span>12% </span> */}
        </div>
        <div className="ai-insight-points-container">
          {topicsList?.map((e) => {
            return (
              <div className="ai-insight-list">
                {/* <Checkbox
                  {...label}
                  checked={e.metric_label === "Yes"}
                  sx={{
                    color: "blue[500]",
                    "&.Mui-checked": {
                      color: "blue[500]",
                    },
                  }}
                  // onClick={() => {
                  //   if(e.metric_label === "Yes")
                  //     setMetricLabel(e.metric_id,"No")
                  //   else if(e.metric_label === "No")
                  //     setMetricLabel(e.metric_id,"Yes")
                  //   else
                  //     console.log("No action for checkbox was taken")
                  // }}
                /> */}
                {e.metric_label === "Yes" ? (
                  <img
                    src={checkboxTrue}
                    style={{ paddingRight: "10px", paddingTop: "1px" }}
                  />
                ) : (
                  <img
                    src={checkboxFalse}
                    style={{ paddingRight: "10px", paddingTop: "1px" }}
                  />
                )}
                <span className="ai-list-text">{e.metric_value}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="aiinsight-aiinsight-wrapper-scrolless">
        <span className="heading-text">Playbook Compliance</span>
        <div className="circle-heading-div">
          <Doughnut
            data={getDonutChartData(parseInt(isNaN(playbookScore) ? 0 : playbookScore))}
            options={getDonutChartOptions(parseInt(isNaN(playbookScore) ? 0 : playbookScore))}
          />
          {/* <span>42% </span> */}
        </div>
        <div className="ai-insight-points-container">
          {playbookList?.map((e) => {
            return (
              <div className="ai-insight-list">
                {/* <Checkbox
                  {...label}
                  checked={e.metric_label === "Yes"}
                  sx={{
                    color: "blue[500]",
                    "&.Mui-checked": {
                      color: "blue[500]",
                    },
                  }}
                  // onClick={() => {
                  //   if(e.metric_label === "Yes")
                  //     setMetricLabel(e.metric_id,"No")
                  //   else if(e.metric_label === "No")
                  //     setMetricLabel(e.metric_id,"Yes")
                  //   else
                  //     console.log("No action for checkbox was taken")
                  // }}
                /> */}
                {/* <span className="info-text">{e.metric_value}</span> */}

                {e.metric_label === "Yes" ? (
                  <img
                    src={checkboxTrue}
                    style={{ paddingRight: "10px", paddingTop: "1px" }}
                  />
                ) : (
                  <img
                    src={checkboxFalse}
                    style={{ paddingRight: "10px", paddingTop: "1px" }}
                  />
                )}
                <span className="ai-list-text">{e.metric_value}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="aiinsight-aiinsight-wrapper-scrolless">
        <span className="heading-text">Communication Score</span>
        <div className="circle-heading-div">
          <Doughnut
            data={getDonutChartData(isNaN(communicationScore) ? 0 : communicationScore )}
            options={getDonutChartOptions(parseInt(isNaN(communicationScore) ? 0 : communicationScore ))}
          />
          {/* <span>32% </span> */}
        </div>
        <div className="ai-insight-points-container">
          {communicationList?.map((e) => {
            return (
              <div className="ai-insight-list">
                {/* <Checkbox
                  {...label}
                  checked={e.metric_label === "yes"}
                  sx={{
                    color: "blue[500]",
                    "&.Mui-checked": {
                      color: "blue[500]",
                    },
                  }}
                  onClick={() => {
                    if(e.metric_label === "yes")
                      setMetricLabel(e.metric_id,"No")
                    else if(e.metric_label === "No")
                      setMetricLabel(e.metric_id,"Yes")
                    else
                      console.log("No action for checkbox was taken")
                  }}
                /> */}

                <img
                  src={listPoint}
                  style={{
                    paddingRight: "10px",
                    marginTop: "6px",
                    height: "5px",
                  }}
                />
                <span className="ai-list-text">{e.metric_value}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="aiinsight-aiinsight-wrapper ">
        <span className="heading-text ">Assesment Score</span>

        <div className="assement-top-panel ">
          <Radar data={dataRadar} options={options2} className="radar-chart" />
          {/* assessment-radar */}
        </div>
        <div className="assement-bottom-panel ">
          <span className="heading-text">Key Questions</span>
          {keyQuestionsList?.map((question, index) => {
            return (
              <div className="ai-insight-list">
                {/* <Checkbox
                onClick={(question) => {
                    if(question.metric_label === "yes")
                      setMetricLabel(question.metric_id,"No")
                    else if(question.metric_label === "No")
                      setMetricLabel(question.metric_id,"Yes")
                    else
                      console.log("No action for checkbox was taken")
                  }}
              /> */}

                <img
                  src={listPoint}
                  style={{
                    paddingRight: "10px",
                    marginTop: "6px",
                    height: "5px",
                  }}
                />
                <span className="ai-list-text">{question.metric_value}</span>
                {/* <span className="info-text">
                  {"- " + question.metric_value}
                </span> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
