import axios from "axios";
import util from "./util";

export async function getUsers(organisation_id) {
  // const apiName = "GetUsersAPI";
  const baseURL = util.baseURL;
  const path = "/users";

  const parameters = "?organisation_id=" + organisation_id;
  const data = await axios.get(`${baseURL + path + parameters}`);
  // return await API.get(apiName, path);
  return data;
}

export async function getUserVideoMetadata(user_id) {
  const baseURL = util.baseURL;
  const path = "/user_video_metadata";

  const parameters = "?user_id=" + user_id;
  const data = await axios.get(`${baseURL + path + parameters}`);
  // return await API.get(apiName, path);
  return data;
}

export const getUserDetails = async (user_id) => {
  const baseURL = util.baseURL;
  const path = "/user_details";

  const parameters = "?user_id=" + user_id;
  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
};

export const updateLanguageAPI = async (user_id, language) => {
  const baseURL = util.baseURL;
  const path = "/update_language";

  const params = {
    user_id: user_id,
    language: language,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const getUserAnalysis = async (org_id, user_id) => {
  const baseURL = util.baseURL;
  const path = "/user_analysis";

  const parameters = "?user_id=" + user_id + "&org_id=" + org_id;
  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
};

export const getMeetingListPerUser = async (user_id) => {
  const baseURL = util.baseURL;
  const path = "/meeting_list_per_user";

  const parameters = "?user_id=" + user_id;
  const data = await axios.get(`${baseURL + path + parameters}`);

  return data;
}


export const getProfilePicturePresignUrl = async (
  user_id,
  organisation_id,
  imageFormat
) => {
  const baseURL = util.baseURL;
  const path = "/update_profile_pic";
  const parameters =
    "?user_id=" +
    user_id +
    "&org_id=" +
    organisation_id +
    "&image_format=" +
    imageFormat;

  console.log(
    "calling getProfilePicturePresignUrl with user_id - ",
    user_id,
    "organisation_id- ",
    organisation_id,
    "  image format - ",
    imageFormat
  );

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const generateProfilePictureUrls = async (target_type, target_id) => {
  // target_type can be 'user', 'team', 'organisation'
  const baseURL = util.baseURL;
  const path = `/get_profile_pic_urls?target_id=${target_id}&target_type=${target_type}`;
  const response = await axios.get(`${baseURL + path}`);
  return response;
}