import React, { useEffect, useState } from "react";
import "./AnalyticsPerPerson.css";
import ASSETS from "../../assets/Assets";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { getUserVideoMetadata, getUserAnalysis } from "../../api/Users";
import { getMeetingData } from "../../api/dashboardAPI";
import PulseLoader from "react-spinners/PulseLoader";
import { useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DashboardVideoModal from "../../components/dashboard/dashboard_highlights/DashboardVideoModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F3F4",
    color: "#272727",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AnalyticsPerPerson({ data1 }) {
  const organisation_id = useSelector((store) => {
    // console.log(store);
    if (store.user.user_details === null) {
      return "";
    }
    return store.user.user_details.organisation_id;
  });
  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");
  const [loading, setLoading] = useState(true);
  console.log(data1);
  const curUser = data1.filter((e) => {
    return e.user_id === user;
  });

  console.log(curUser);

  const [videoMetadata, setVideoMetadata] = useState([]);
  const [categoriesCount, setCategoriesCount] = useState([1, 0, 1]);
  const [videoDisplayList, setVideoDisplayList] = useState([]);
  //get the calculated categories in these data -
  const data = {
    name: "John KellyJohn KellyJohn KellyJohn KellyJohn KellyJohn KellyJohn Kelly",
    user_id: "djchbjdshcbjhcsdjcdcs",
    always_be_closing:
      categoriesCount[0] + categoriesCount[1] + categoriesCount[2] === 0
        ? 0
        : Math.round(
            (categoriesCount[2] /
              (categoriesCount[0] + categoriesCount[1] + categoriesCount[2])) *
              100
          ),
    conversion:
      categoriesCount[0] + categoriesCount[1] + categoriesCount[2] === 0
        ? 0
        : Math.round(
            (categoriesCount[1] /
              (categoriesCount[0] + categoriesCount[1] + categoriesCount[2])) *
              100
          ),
    needs_improvement:
      categoriesCount[0] + categoriesCount[1] + categoriesCount[2] === 0
        ? 0
        : Math.round(
            (categoriesCount[0] /
              (categoriesCount[0] + categoriesCount[1] + categoriesCount[2])) *
              100
          ),
    meetings: videoMetadata,
  };

  const reformatBenchmarks = (benchmarks) => {
    let len = benchmarks.length;
    let map = new Map();
    for (let i = 0; i < len; i++) {
      let currObj = benchmarks[i];
      map.set(currObj.metric_type, currObj.score);
    }
    console.log("reformatted benchmarks - ", map);
    return map;
  };

  const getVideoScores = (videoScores, benchmarks) => {
    let map = new Map();
    let len = videoScores.length;
    let benchmarksMap = reformatBenchmarks(benchmarks);
    for (let i = 0; i < len; i++) {
      let currObj = videoScores[i];
      let id = currObj.video_id;
      if (currObj.score < benchmarksMap.get(currObj.metric_type)) {
        if (!map.has(id)) {
          map.set(id, 0);
        }
      } else {
        if (map.has(id)) {
          map.set(id, map.get(id) + 1);
        } else {
          map.set(id, 1);
        }
      }
    }
    console.log("video score count map - ", map);
    //COUNTING THE VARIOUS CATEGORIES FROM THE VIDOE MAP
    generateVideoList(map);
    return map;
  };

  const getCategory = (params_greater_than_benchmark) => {
    if (params_greater_than_benchmark < 2) return "Needs Improvement";
    else if (params_greater_than_benchmark < 5) return "Conversion";
    else return "Always be closing";
  };

  const generateVideoList = async (videoScoresCountMap) => {
    let video_list = [];
    videoScoresCountMap.forEach((value, key) => {
      video_list.push(key);
    });
    setVideoDisplayList([]);
    let response = await getMeetingData(video_list);
    let fetchedVideo = response.data;
    console.log("video list from meeting data - ", response.data);
    let len = fetchedVideo.length;
    let videoDisplay = [];
    for (let i = 0; i < len; i++) {
      let currObj = {
        start_time: fetchedVideo[i].start_time,
        video_id: fetchedVideo[i].video_id,
        topic: fetchedVideo[i].topic,
        duration: fetchedVideo[i].duration,
        category: getCategory(
          videoScoresCountMap.get(fetchedVideo[i].video_id)
        ),
      };
      videoDisplay.push(currObj);
    }
    console.log("video list to display - ", videoDisplay);
    setVideoDisplayList(videoDisplay);
    setLoading(false);
    return videoDisplay;
  };

  const categorizeWithBenchmark = (videoScores) => {
    let size = videoScores.size;
    let cat1 = 0; //bad
    let cat2 = 0; //medium

    let cat3 = 0; //best
    videoScores.forEach((value, key) => {
      if (value < 2) {
        cat1++;
      } else if (value <= 4) {
        cat2++;
      } else {
        cat3++;
      }
    });

    return [cat1, cat2, cat3];
  };

  const fetchUserAnalysis = async (user_id) => {
    // let org_id = "test_org";
    let org_id = organisation_id;
    // user_id = "wdqdqwadq";
    let response = await getUserAnalysis(org_id, user_id);
    setVideoDisplayList(response.data);
    // console.log("response of user analysis - ", response.data);
    // let videoScores = response.data[0];
    // let benchmarks = response.data[1];
    // let res = categorizeWithBenchmark(getVideoScores(videoScores, benchmarks));
    // console.log("categories count - ", res);
    // setCategoriesCount(res);
    setLoading(false);
  };

  const fetchUserVideoMetadata = async (user_id) => {
    // let user_id = 'wdqdqwadq';
    // let user_id = user
    // setVideoMetadata(response.data);
    console.log("fecthing metadata for user -", user_id);

    let response = await getUserVideoMetadata(user_id);
    console.log("user video metadata response - ", response.data);
    setVideoMetadata(response.data);
  };

  useEffect(() => {
    if (user != null) {
      fetchUserVideoMetadata(user);
      fetchUserAnalysis(user);
      console.log("analytics per person useEffect ran");
    }
  }, [user]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const table = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          marginTop: "1rem",
          maxHeight: "calc(calc(100% - 4.5rem))",
        }}
      >
        <Table
          sx={{ minWidth: 600, width: "100%" }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="center">Video</StyledTableCell> */}
              <StyledTableCell align="left" width="45%">
                Name
              </StyledTableCell>
              <StyledTableCell align="center" width="30%">
                Date
              </StyledTableCell>
              <StyledTableCell align="center" width="20%">
                Duration
              </StyledTableCell>
              {/* <StyledTableCell align="center">Category</StyledTableCell> */}
              {/* <StyledTableCell align="center">Overall Score</StyledTableCell> */}
              <StyledTableCell width="5%"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videoDisplayList.map((row) => {
              const link =
                "/analytics?user=" + user + "&meeting=" + row.video_id;
              return (
                <StyledTableRow key={row.video_id}>
                  {/* <StyledTableCell align="center">
                    <div className="video-modal-highlights">
                      <DashboardVideoModal />
                    </div>
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center">{row.topic}</StyledTableCell> */}
                  <StyledTableCell align="left">{row.title}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.start_time}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {secondsToHms(row.duration)}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    <div
                      className={
                        row.category === "Always be closing"
                          ? "category-bubble-green"
                          : row.category === "Needs Improvement"
                          ? "category-bubble-red"
                          : "category-bubble-yellow"
                      }
                    >
                      {row.category}
                    </div>
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center">
                    {Math.floor(Math.random() * 100)} %
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <Link to={link}>
                      <ArrowForwardIcon />
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const profilePicData = useSelector((e) => {
    return e.user.profile_pictures;
  });

  if (user !== null) {
    if (loading) return <PulseLoader size={40} color={"#e8e8ff"} />;
    else
      return (
        <div className="analytics-per-peropn-wrapper">
          <div className="member-list-member">
          {
            profilePicData !== undefined && profilePicData.hasOwnProperty(user) ? 
            <div className="profile-pic-small-div user-list-spacing">
              <img src={profilePicData[user]} className="profile-pic-small-div " />
            </div>
            :
            <img src={ASSETS.user} className="user-image" />
          }
            <p className="person-analytics-name">
              {console.log(curUser)}
              {curUser == [] || curUser[0] == undefined
                ? null
                : curUser[0].name}
            </p>
          </div>
          {/* <div className="person-analytics-header-wrapper">
            <div
              className="header-details margin-right-2"
              style={{ backgroundColor: "#E8E8FF" }}
            >
              <p className="heading-details-heading">Always be closing</p>
              <p className="heading-details-value">{data.always_be_closing}%</p>
            </div>
            <div
              className="header-details margin-right-2"
              style={{ backgroundColor: "#EBF6FF" }}
            >
              <p className="heading-details-heading">Conversion</p>
              <p className="heading-details-value">{data.conversion}%</p>
            </div>
            <div
              className="header-details"
              style={{ backgroundColor: "#FFEEF5" }}
            >
              <p className="heading-details-heading">Needs improvement</p>
              <p className="heading-details-value">{data.needs_improvement}%</p>
            </div>
          </div> */}
          {table()}
        </div>
      );
  }
}
