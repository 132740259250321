import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      display: false
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

const labels = ['Score', 'Benchmark'];

export default function HorizontalBar({ score, benchmark }) {
  const data = {
    labels,
    datasets: [
      {
        data: [score, benchmark],
        borderColor: ["#969AFF", "rgb(255, 99, 132)"],
        backgroundColor: ["#E8E8FF", "rgba(255, 99, 132, 0.5)"],
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
