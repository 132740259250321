import React, { useState, useEffect } from "react";
import "./AnalyticsPerPerson.css";
import ASSETS from "../../assets/Assets";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import "./MeetingAnalysis.css";
// import ReactPlayer from "react-player";
import Chart from "./Chart";
import { BsThreeDotsVertical } from "react-icons/bs";
import Popup from "reactjs-popup";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import {
  analyticsPerMeetTranscriptAPI,
  analyticsPerMeetMetricsAPI,
  analyticsPerMeetAPI,
  analyticsPerMeetFeedbackUpdateAPI,
  videoAPI,
  analyticsPerMeetUpdateTranscriptCommentAPI,
} from "../../api/analyticsPerMeet";
import { FiEdit } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { readBenchmarkAPI, updateBenchmarkAPI } from "../../api/setting";
import VideoThumbnail from "react-video-thumbnail";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F42D77",
  "&:hover": {
    backgroundColor: "rgba(244, 45, 119, 0.66);",
    color: "white",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "1rem",
}));

const metric_types = [
  "mirroring",
  "confidence",
  "wording_sales",
  "wording_client",
  "speech_rally",
  "appropriate_answers",
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F3F4",
    color: "#272727",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AnalyticsPerPerson({
  meetingData,
  feedback,
  setFeedback,
  setMeetingData,
}) {
  const organisation_id = useSelector((store) => {
    console.log(store);
    if (store.user.user_details === null) {
      return null;
    }
    return store.user.user_details.organisation_id;
  });

  const [overallCount, setOverallCount] = useState(0);
  // console.log(overallCount);

  const [benchmarkScore, setBenchMarkScore] = useState([]);
  console.log(benchmarkScore);

  const getMetricDetails = (metric_type) => {
    if (benchmarkScore == []) {
      return 0;
    }
    // console.log(benchmarkScore);
    const temp = benchmarkScore.filter((e) => {
      return e.metric_type === metric_type;
    })[0];
    // // console.log(temp);
    if (temp === undefined) {
      return 0;
    }
    if (temp.org_score !== null) {
      return temp.org_score;
    }
    return temp.score;
  };

  const [metricData, setMetricData] = useState({
    mirroring: "",
    confidence: "",
    wording_sales: "",
    wording_client: "",
    speech_rally: "",
    appropriate_answers: "",
  });

  console.log(metricData);

  useEffect(() => {
    console.log(organisation_id);
    // console.log(benchmarkScore);

    var tempCount = 0;
    for (var i = 0; i < metric_types.length; i = i + 1) {
      const metric = metric_types[i];
      // console.log(metric);
      // console.log(parseFloat(metricData[metric]));
      // console.log(getMetricDetails(metric));
      var value = parseFloat(metricData[metric]);
      if (isNaN(value)) {
        value = 0;
      }
      if (value > getMetricDetails(metric)) {
        // setOverallCount(overallCount + 1);
        tempCount = tempCount + 1;
      }
    }
    console.log(tempCount);
    setOverallCount(tempCount);
  }, [benchmarkScore, metricData]);

  const fetchBenchmarkHelper = async () => {
    var result = await readBenchmarkAPI(organisation_id);
    console.log("organisation id is: " + organisation_id);
    console.log(result.data);
    setBenchMarkScore([...result.data]);
  };

  useEffect(() => {
    fetchBenchmarkHelper();
  }, [organisation_id]);

  // // console.log(feedback);
  // // console.log(meetingData);
  const [rawTranscriptData, setRawTranscriptData] = useState([]);
  const [rawMetricsData, setRawMetricsData] = useState();

  const [presignedUrl, setPresignedUrl] = useState("");
  // // console.log(presignedUrl);

  // // console.log(rawTranscriptData);
  // // console.log(rawMetricsData);

  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");
  const meeting = params.get("meeting");

  const getTranscriptData = async () => {
    const tempData = await analyticsPerMeetTranscriptAPI(meeting);
    // // console.log(tempData.data);
    setRawTranscriptData(
      tempData.data.sort((a, b) => a.start_time - b.start_time)
    );
  };

  const getMetricsData = async () => {
    // console.log(meeting);

    const tempData = await analyticsPerMeetMetricsAPI(meeting);
    setRawMetricsData(tempData.data);

    var temp = {
      mirroring: "",
      confidence: "",
      wording_sales: "",
      wording_client: "",
      speech_rally: "",
      appropriate_answers: "",
    };
    for (var i = 0; i < tempData.data.length; i = i + 1) {
      temp[tempData.data[i].metric_type] = tempData.data[i].score;
    }

    // // console.log(tempData.data);
    // // console.log(temp);
    setMetricData({ ...temp });
  };

  const getPresignedUrl = async () => {
    const tempData = await videoAPI(meeting);
    // // console.log(tempData.data.presigned_url);
    setPresignedUrl(tempData.data.presigned_url);
  };

  useEffect(() => {
    // // console.log("use efect called");
    getTranscriptData();
    getMetricsData();
    getPresignedUrl();
  }, [meeting]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const [selectedMetric, setSelectedMetric] = useState("overall");

  const updateFeedbackHelper = async () => {
    setFeedback({ ...meetingData, edit: "loading" });
    const response = await analyticsPerMeetFeedbackUpdateAPI(meeting, feedback);
    // // console.log(feedback);
    // // console.log(response);
    setMeetingData({ ...meetingData, feedback: feedback.text });
    setFeedback({ ...meetingData, edit: false });
  };

  // const table = () => {
  //   return (
  //     <TableContainer
  //       component={Paper}
  //       sx={{ maxHeight: "calc(100vh - 11rem)" }}
  //     >
  //       <Table
  //         sx={{ minWidth: 600 }}
  //         aria-label="customized table"
  //         stickyHeader
  //       >
  //         <TableHead>
  //           <TableRow>
  //             <StyledTableCell align="center">Timestamp</StyledTableCell>
  //             <StyledTableCell align="center">Transcript</StyledTableCell>
  //             <StyledTableCell align="center">Comment</StyledTableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {data.transcripts.map((row) => (
  //             <StyledTableRow key={row.meeting_id}>
  //               <StyledTableCell align="center">
  //                 {secondsToHms(row.timestamp)}
  //               </StyledTableCell>
  //               <StyledTableCell align="center">
  //                 {row.transcript}
  //               </StyledTableCell>
  //               <StyledTableCell align="center">{row.comment}</StyledTableCell>
  //             </StyledTableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
  // };

  const [comment, setComment] = useState("");
  // // console.log(comment)
  const [commentLoading, setCommentLoading] = useState(false);

  const updateCommentHandler = async (transcript_id) => {
    // // console.log(meeting)
    // // console.log(transcript_id)
    // // console.log(comment)
    setCommentLoading(true);

    const result = await analyticsPerMeetUpdateTranscriptCommentAPI(
      meeting,
      transcript_id,
      comment
    );
    // console.log(result);
    setRawTranscriptData((current) =>
      current.map((obj) => {
        if (obj.transcript_id === transcript_id) {
          return { ...obj, comment: comment };
        }
        return obj;
      })
    );

    setCommentLoading(false);
  };

  const displayTranscript = (value) => {
    return (
      <div className="transcript-wrapper">
        <img className="transcript-photo" src={ASSETS.user} />
        <div className="transcript-text">
          <p className="transcript-user">
            {value.participant_name.replace("Audio only - ", "")}
          </p>
          <p className="transcript-time">{secondsToHms(value.start_time)}</p>
          <p className="transcript-content">{value.transcript}</p>
          {value.comment !== null && value.comment !== "" ? (
            <p className="transcript-comment">{value.comment}</p>
          ) : null}
        </div>
        <Popup
          trigger={
            <button className="button three-dots">
              <BsThreeDotsVertical className="three-dots" />
            </button>
          }
          position="bottom right"
          // on={["hover", "focus"]}
          on={["click"]}
          arrow="true"
        >
          {commentLoading === false ? (
            <div className="popup-wrapper">
              <p>Add comment</p>
              <OutlinedInput
                placeholder=""
                size="small"
                sx={{ width: "400px" }}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
              <ColorButton
                variant="contained"
                onClick={() => {
                  updateCommentHandler(value.transcript_id);
                }}
              >
                Save
              </ColorButton>
            </div>
          ) : (
            <div className="popup-wrapper">
              <img style={{ width: "100px" }} src={ASSETS.loading} />
            </div>
          )}
        </Popup>
      </div>
    );
  };

  const [styleClass, setStyleClass] = useState({
    overall: "metric ",
    mirroring: "metric ",
    confidence: "metric ",
    wording_sales: "metric ",
    wording_client: "metric ",
    speech_rally: "metric ",
    appropriate_answers: "metric ",
  });

  useEffect(() => {
    selectClass();
  }, [benchmarkScore, metricData, selectedMetric, overallCount]);

  const selectClass = () => {
    var newClass = {
      overall: "",
      mirroring: "",
      confidence: "",
      wording_sales: "",
      wording_client: "",
      speech_rally: "",
      appropriate_answers: "",
    };

    // console.log(overallCount);
    var temp2 = "metric";
    if (selectedMetric === "overall") {
      temp2 = temp2 + " " + "active";
    }
    if (overallCount >= 5) {
      temp2 = temp2 + " good";
    } else if (overallCount >= 2) {
      temp2 = temp2 + " good";
    } else {
      temp2 = temp2 + " bad";
    }

    newClass["overall"] = temp2;

    for (var i = 0; i < metric_types.length; i++) {
      var metric = metric_types[i];
      var temp = "metric";
      // console.log(metric);
      if (metric === selectedMetric) {
        temp = temp + " " + "active";
      }
      // add good or bad

      var value = parseFloat(metricData[metric]);
      if (isNaN(value)) {
        value = 0;
      }
      // console.log(metric);
      // console.log(value);
      // console.log(getMetricDetails(metric));
      if (value >= getMetricDetails(metric)) {
        temp = temp + " good";
      } else {
        temp = temp + " bad";
      }

      newClass[metric] = temp;
    }

    // console.log(newClass);

    setStyleClass({ ...newClass });
  };

  return (
    <div className="analytics-per-peropn-wrapper view">
      <div className="meeting-content-wrapper" style={{ marginTop: "0rem" }}>
        <div className="meeting-video-section-wrapper meeting-scroll-wrapper hide-scroll">
          {console.log(presignedUrl)}
          <video
            width="100%"
            src={presignedUrl}
            className="view"
            controls={true}
          />
          {/* <div className="view">
            <VideoThumbnail
              videoUrl={presignedUrl}
              thumbnailHandler={(thumbnail) => console.log(thumbnail)}
              width={120}
              height={80}
              snapshotAtTime={2}
            />
          </div>
          <img src={presignedUrl} className="highlight-item-image" /> */}
          <p
            onClick={() => {
              setSelectedMetric("overall");
            }}
            className={styleClass["overall"]}
          >
            <span>Overall Meeting Category:</span>{" "}
            <span>
              {overallCount >= 5
                ? "Always be closing"
                : overallCount >= 2
                ? "Conversion"
                : "Needs improvement"}
            </span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("mirroring");
            }}
            className={styleClass["mirroring"]}
          >
            <span>Mirroring the Client:</span>{" "}
            <span>{metricData.mirroring}%</span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("confidence");
            }}
            className={styleClass["confidence"]}
          >
            <span>Confidence of Sales Member:</span>{" "}
            <span>{metricData.confidence}%</span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("wording_sales");
            }}
            className={styleClass["wording_sales"]}
          >
            <span>Appropriate wording from Sales:</span>{" "}
            <span>{metricData.wording_sales}</span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("wording_client");
            }}
            className={styleClass["wording_client"]}
          >
            <span>Appropriate wording from Client:</span>{" "}
            <span>{metricData.wording_client}</span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("speech_rally");
            }}
            className={styleClass["speech_rally"]}
          >
            <span>Speech Rally:</span> <span>{metricData.speech_rally}</span>
          </p>
          <p
            onClick={() => {
              setSelectedMetric("appropriate_answers");
            }}
            className={styleClass["appropriate_answers"]}
          >
            <span>Appropriate Answers to questions and doubts</span>{" "}
            <span>{metricData.appropriate_answers}%</span>
          </p>
          <p
            className="meeting-content-iinside-heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Feedback:{" "}
            <FiEdit
              style={
                feedback.edit === "loading"
                  ? { display: "none" }
                  : { cursor: "pointer" }
              }
              onClick={() => {
                const temp = feedback.edit;
                setFeedback({ ...feedback, edit: !temp });
              }}
            />
          </p>
          {feedback.edit === true ? (
            <>
              <OutlinedInput
                placeholder="Search"
                size="small"
                sx={{ marginBottom: "1rem", width: "100%" }}
                multiline
                rows={4}
                onChange={(e) => {
                  setFeedback({ ...feedback, text: e.target.value });
                }}
              />
              <ColorButton
                variant="contained"
                onClick={() => {
                  updateFeedbackHelper();
                }}
              >
                Save
              </ColorButton>
            </>
          ) : feedback.edit === "loading" ? (
            <img src={ASSETS.loading} />
          ) : (
            <p className="feedback-test">
              {meetingData !== null && meetingData !== undefined
                ? meetingData.feedback
                : null}
            </p>
          )}
        </div>
        <div className="meeting-transcript-section-wrapper meeting-scroll-wrapper hide-scroll">
          <div style={{ height: "20rem" }}>
            <Chart
              metric={selectedMetric}
              getMetricDetails={getMetricDetails}
              overallCount={overallCount}
              appropriate_answers={metricData.appropriate_answers}
              wording_sales={metricData.wording_sales}
              wording_client={metricData.wording_client}
              rally={rawTranscriptData}
            />
          </div>
          <p className="meeting-content-iinside-heading">Transcript</p>
          {/* {table()} */}
          {rawTranscriptData.map((value) => {
            return displayTranscript(value);
          })}
        </div>
      </div>
    </div>
  );
}
