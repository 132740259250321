import React from "react";
import "./Profile.css";
import Slider from "@mui/material/Slider";
import { useFilePicker } from "use-file-picker";
import {
  getProfilePicturePresignUrl,
  generateProfilePictureUrls,
} from "../../api/Users";
import { useState, useEffect } from "react";
import Compressor from "compressorjs";
import { fetchProfilePicturesSuccess } from "../../store/user/userAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { defaultProfilePictureURL } from "../../utils/helperFunctions";
import { getUserDetails } from "../../api/Users";

const Profile = () => {
  const [imageChosen, setImageChosen] = useState(false);
  const [userName, setUserName] = useState("loading");
  const [email, setEmail] = useState("loading");
  const dispatch = useDispatch();
  const user_id = useSelector((e) => {
    return e.user.user_details.user_id;
  });
  const org_id = useSelector((store) => {
    console.log(store);
    if (store.user.user_details === null) {
      return "";
    }
    return store.user.user_details.organisation_id;
  });
  const organisation_id = org_id;
  const profilePicData = useSelector((store) => {
    return store.user.profile_pictures;
  });
  // var profilePicURL = "https://miro.medium.com/v2/resize:fit:882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif"
  var profilePicURL = defaultProfilePictureURL;
  if (
    profilePicData &&
    typeof profilePicData === "object" &&
    user_id in profilePicData
  ) {
    var profilePicURL = profilePicData[user_id];
    console.log("Profile Pic:", profilePicURL);
  } else {
    console.log(
      "The profile pic object is not valid or does not have the user_id property."
    );
  }

  const [
    openFileSelector,
    { filesContent, plainFiles, FileSelectorLoading, errors, clear },
  ] = useFilePicker({
    accept: [".png", ".jpg"],
    readAs: "DataURL",
    onFilesSuccessfulySelected: ({ plainFiles, filesContent, errors }) => {
      console.log(
        "onFilesSelected files are selected",
        plainFiles,
        filesContent,
        errors
      );
    },
  });

  const fetchUserDetails = async (user_id) => {
    let response = await getUserDetails(user_id);
    console.log("user detais fetched - ", response.data);
    setEmail(response.data[0].email_id);
    setUserName(response.data[0].name);
  };

  useEffect(() => {
    if (filesContent.length) {
      setImageChosen(true);
      updateProfilePicWithPresigned();
    }
    console.log("image chosen - ", imageChosen);
  }, [filesContent]);

  useEffect(() => {
    fetchUserProfilePicture(user_id);
    fetchUserDetails(user_id);
  }, []);

  const fetchUserProfilePicture = async (user_id) => {
    let response = await generateProfilePictureUrls("user", user_id);
    console.log("profile picture fetch results - ", response.data);
    dispatch(fetchProfilePicturesSuccess(response.data));
  };

  console.log("users profile picture link - ", profilePicURL);

  const updateProfilePicWithPresigned = () => {
    let imageFormat = filesContent[0].name.split(".").pop();

    getProfilePicturePresignUrl(user_id, organisation_id, imageFormat)
      .then(async (res) => {
        //Getting presigned URL
        const presignedURL = res.data;
        console.log("presigned url obtained - ", presignedURL);
        console.log("files content - ", filesContent);
        let blob = await (await fetch(filesContent[0].content)).blob();
        console.log("uncompressed blob - ", blob);

        //COMPRESSING THE IMAGE -
        // const file = e.target.files[0];
        let file = blob;
        if (!file) {
          return;
        }
        let qualityFactor = 100000 / file.size;

        console.log(
          "size of blob - ",
          file.size,
          " compression quality factor - ",
          qualityFactor
        );
        if (qualityFactor < 0.8) {
          new Compressor(file, {
            quality: qualityFactor,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            async success(resultImg) {
              // const formData = new FormData();

              // The third parameter is required for server
              // formData.append("file", result, result.name);

              // Send the compressed image file to server with XMLHttpRequest.
              console.log("compressed result - ", resultImg);
              blob = resultImg;
              // axios.post("/path/to/upload", formData).then(() => {
              //   console.log("Upload success");
              // });

              console.log("uploading the blob - ", blob);
              //Uploading to S3
              const result = await fetch(presignedURL, {
                method: "PUT",
                body: blob,
              });
              console.log(
                "result of presigned url fetch of the blob - ",
                result
              );
              if (result.status === 200) {
                //setting all data to default if success

                clear();
                alert("File Uploaded");

                fetchUserProfilePicture();
                // navigate("/");
                // window.location.reload();
              } else {
                alert("Something went wrong !");
              }
            },
            error(err) {
              console.log(
                "printing error message for updateproflepicture func - ",
                err.message
              );
            },
          });
        } else {
          console.log("uploading the blob - ", blob);
          //Uploading to S3
          const result = await fetch(presignedURL, {
            method: "PUT",
            body: blob,
          });
          console.log("result of presigned url fetch of the blob - ", result);
          if (result.status === 200) {
            //setting all data to default if success

            clear();
            alert("File Uploaded");

            fetchUserProfilePicture();
            // navigate("/");
            window.location.reload();
          } else {
            alert("Something went wrong !");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        setImageChosen(false);
      });
  };

  return (
    <div className="profile-root">
      <div className="profile-segment">
        <div className="profile-details-part">
          <div className="profile-picture-big">
            <img
              className="profile-picture-big"
              onClick={openFileSelector}
              src={profilePicURL}
              //  src="https://sales-enablement-profilepic-bucket.s3.amazonaws.com/3c9e055d-f6d8-4a94-a27a-437eaa3c7b1d/ffea1fb6-8c6d-4fd5-b41c-3a429359c110/profile_pic.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAXKNSHYQGSYUQOVXV%2F20231014%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20231014T120405Z&X-Amz-Expires=10000&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECwaDmFwLW5vcnRoZWFzdC0xIkgwRgIhALjiSUxfXNJNejCYk5uolquiY8U38YpRkSRuMUGK5TxkAiEArO3oLv6LhY2OagmaiTxTeWFFMONjdCm9WoS89NkLQ5EqpAMIRRABGgw1MDM0MjE4NDY1NDEiDAn7BPip80vgmrW8eiqBA2RGSi3TlYO4g%2Fy64qnzrrT5qy2rb6INCwdfy%2BmAjsKG5IIeLxRymiu%2B58klMlpu0JbgyxSyTrOC4kBB41KvX%2FDPRnVLpxqMnkph7Nm5fpiMtU35cHOF%2FCG7JWmk9JdU48yz6RC7FzMKh5LpgF6IxJ6USGw5lAS8h7pMJCk6afoHiF0D2P9n82YLaukIaF9D9O76hdDzVmaz%2Fkr%2FmwiSrXxQK2zYFIrPUOszoyZ7OFQEiuy3uRIvd%2BqaS2NOkbxj5kR8oPeL9oVCJWMhhiyJi9tdUi2ClaNwFq%2Buq0LbXyZPWq3PeU%2BmtWtXwHIm33st5yVWv8EIt0rZbk1bHF5cjRZxB4T82WK0vx47RYG%2F3KHLnbPYmR7uXnjGSFrHP5xhr%2BUvXQPNeuxsVCzxMZt3OJHH6xuvM0uDtl%2BDzxJBByAdZdGyvEgJs6pnez5o2nUpJILuIuw0ZsdWOQlEj2spqhi%2Fj8L9jHdpRUwTrW3ex%2BQdmL0ciyEoIgAt18M82qCqHsQwyoaqqQY6nAGevOn2xJ2BjOoeU2AZMkZ7Cz6XS55cCLZac3dT36KdGMF3Kkta4Hzry0GlaQi%2FC6yoflJTeTAr92kGhQSouU5pIWmDr7q%2FHFhAGzI%2BZtvuPbaa50kBeRXhwfLYT5PfvPEUlbJ2ue%2BD4ShK2v5hkBYHfAsFzP9dl0rQuARmE%2Fd5yQenDEg6Bk24UhiYFzN3O8k1rFGydmVG3lGd9BU%3D&X-Amz-Signature=94930cf14b7f328c897729ea1f2f24c5f0ad083f59e97280e4a93d1f2d13ea57"
            />
          </div>
          <div className="profile-details-column">
            <span className="heading-text">{userName}</span>
            <span className="info-text">{email}</span>
          </div>
        </div>
      </div>
      <div className="profile-segment">
        {/* <div className="profile-benchmark-part">
      <span className="heading-text">Benchmarks</span>
        <div className="slider-entry">
        <span >Preparation</span>
        <Slider aria-label="Volume" value={null} onChange={null} />
        </div>
        <div className="slider-entry">
        <span>Preparation</span>
        <Slider aria-label="Volume"  />
        </div>
        <div className="slider-entry">
        <span>Preparation</span>
        <Slider aria-label="Volume"  />
        </div>
        <div className="slider-entry">
        <span>Preparation</span>
        <Slider aria-label="Volume"  />
        </div>
        <div className="slider-entry">
        <span>Preparation</span>
        <Slider aria-label="Volume"  />
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default Profile;
