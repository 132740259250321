// DO NOT EDIT this file , unless you know what you are doing
// const apiUrl = "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1";
import config from "./config";
const apiUrl = "http://127.0.0.1:8080";
const BUCKET_NAME = config.BUCKET_NAME;
const aws = {
  Auth: {
    mandatorySignIn: true,
    /*DEV*/
    // region: "ap-south-1",
    // userPoolId: "ap-south-1_dwEkOEDnC",
    // userPoolWebClientId: "6iveook8ju9sie29tnck4rqmds",
    //identityPoolId: "Not made, we have make it",
    /*PRODUCTION*/
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_hNNMHwHCe",
    userPoolWebClientId: "3t50l3pvdpa44uc27die9kgj77", //App Clied Id
    identityPoolId: "ap-northeast-1:9ad9d238-a37e-451e-8996-8409ee74141e",
  },
  API: {
    endpoints: [
      {
        name: "FaceEmotionsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/face_emotions",
        region: "ap-northeast-1",
      },
      {
        name: "GetUsersAPI",
        endpoint: apiUrl + "/users",
        region: "ap-northeast-1",
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
      region: "ap-northeast-1", //OPTIONAL -  Amazon service region
      isObjectLockEnabled: false, //OPTIONAl - Object Lock parameter
    },
  },
};

export default aws;
