import React from "react";
import "./KeyMoments.css";
import { AiOutlinePlayCircle } from "react-icons/ai";

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default function KeyMoments({
  key_moment_timeline_data,
  seekToTimestamp,
}) {

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    let ret = new Date(d * 1000).toISOString().substr(11, 8);

    if (ret.substring(0, 3) === "00:") {
      ret = ret.substring(3);
    }

    return ret;
  };
  console.log("key_moment_timeline_data", key_moment_timeline_data);
  return (
    <div className="key-moments-wrapper-v2">
    <Timeline
    sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
      {key_moment_timeline_data.map((e, idx) => {
        return (
          <TimelineItem>
          <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="moment-wrapper-v2">
            <div
              className="key-moment-title-v2-new"
              onClick={() => {
                seekToTimestamp(e.start_time);
              }}
            >
              {/* <AiOutlinePlayCircle /> */}

              <span
                style={{
                  marginLeft: "1rem",
                  color: "rgba(61, 88, 162, 1)",
                  fontWeight: "700",
                }}
              >
                Segment: {idx + 1}
              </span>
              <span className="key-moment-time-v2">
                {secondsToHms(e.start_time)} - {secondsToHms(e.end_time)}
              {e.username}
              </span>
              
              
            </div>
            {/* {e.transcript} */}
            <div className="key-moment-content-div">

            <span className="info-text" style={{ color: "rgba(58, 57, 56, 1)" }}>
              {e.metric_value}
            </span>
            </div>
          </div>
          </TimelineContent>
          </TimelineItem>
        );
      })}
      </Timeline>
    </div>
  );
}
