export const fetchUserDetailsSuccess = (data) => {
  return {
    type: "FETCH_USER_DETAILS_SUCCESS",
    payload: data,
  };
};

export const fetchUserDetailsDBSuccess = (data) => {
  return {
    type: "FETCH_USER_DETAILS_DB_SUCCESS",
    payload: data,
  };
};

export const updateLanguageSuccess = (data) => {
  return {
    type: "UPDATE_USER_LANGUAGE_SUCCESS",
    payload: data,
  };
};

export const fetchProfilePicturesSuccess = (data) => {
  return {
    type:"FETCH_PROFILE_PICTURE_SUCCESS",
    payload: data,
  }
}