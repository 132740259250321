import React from "react";
import config from "../../utils/config";
import OutlinedInput from "@mui/material/OutlinedInput";
import ASSETS from "../../assets/Assets";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import GMeet from "./GMeet";
import Profile from "./Profile";
import Zoom from "./Zoom";
import MSTeams from "./MSTeams";
import Files from "./Files";
import "./Settings.css";
import Benchmark from "./Benchmark";
import { useSelector, useDispatch } from "react-redux";

const settingsList = [
  {
    name: "Profile",
    link: "profile",
    icon: ASSETS.user,
  },
  // {
  //   name: "Points",
  //   link: "points",
  //   icon: ASSETS.user,
  // },
];

const settingsList2 = [
  {
    name: "Zoom",
    link: "zoom",
    icon: ASSETS.zoom_logo,
  },
  // {
  //   name: "MS Teams",
  //   link: "msteam",
  //   icon: ASSETS.ms_teams_logo,
  // },
  // {
  //   name: "Google Meet",
  //   link: "gmeet",
  //   icon: ASSETS.google_meet_logo,
  // },
  {
    name: "files",
    link: "files",
    icon: ASSETS.files,
  },
];

export default function Settings() {
  const params = new URLSearchParams(window.location.search);
  // const user = params.get("user");
  const setting = params.get("setting");
  // console.log(setting);

  const role = useSelector((store) => {
    // console.log(store);
    if (store.user.user_details === null) {
      return "staff";
    }
    return store.user.user_details.role;
  });

  // console.log(role);

  const settingPage = () => {
    switch (setting) {
      // case "profile":
      //   return <Profile />;
      case "zoom":
        return <Zoom />;
      // case "msteam":
      //   return <MSTeams />;
      // case "gmeet":
      //   return <GMeet />;
      case "benchmark":
        return <Benchmark />;
      case "files":
        return <Files />;
      default:
    }
  };

  return (
    /* <div className="settings-container">
       Settings
     <div>
        <a
          href={`https://accounts.google.com/o/oauth2/v2/auth?scope=${config.GOOGLE_SCOPE}&response_type=code&redirect_uri=${config.REDIRECT_URL}&client_id=${config.GOOGLE_CLIENT_ID}`}
        >
          Google Meet
        </a>
      </div>
      <div>
        <a
          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${config.ZOOM_CLIENT_ID}&redirect_uri=${config.REDIRECT_URL}`}
        >
          Connect Zoom
        </a>
      </div>
      <div>
        <a
          href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${config.MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${config.REDIRECT_URL}&scope=${config.MICROSOFT_SCOPE}`}
        >
          Microsoft Teams
        </a>
      </div> */
    /* <div>
        <a
          href={`https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${config.SALESFORCE_CLIENT_ID}&redirect_uri=https://cloud-dashboard.imbesideyou.com`}
        >
          Salesforce
        </a>
      </div> */
    <div className="settings-wrapper">
      <div className="analysis-content">
        <div className="settings-list">
          <div className="member-list-names-list">
            {/* {settingsList.map((e) => {
              const link = "/settings?setting=" + e.link;
              return (
                <Link
                  to={link}
                  style={{ textDecoration: "none", width: "100%" }}
                  key={e.user_id}
                >
                  <div
                    className="member-list-member"
                    key={e.link}
                    style={
                      setting === e.link ? { backgroundColor: "#F4F5FF" } : null
                    }
                  >
                    <img src={e.icon} className="user-image" />
                    <p
                      className={
                        setting === e.link
                          ? "member-list-name active"
                          : "member-list-name"
                      }
                    >
                      {e.name}
                    </p>
                    {setting === e.link ? (
                      <AiOutlineRight className="member-name-arrow" />
                    ) : null}
                  </div>
                </Link>
              );
            })}
            <hr style={{ border: "1px solid #eeeeee" }} /> */}

            <Link
              to={"/settings?setting=zoom"}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <div
                className="member-list-member"
                style={
                  setting === "zoom" ? { backgroundColor: "#F4F5FF" } : null
                }
              >
                <img src={ASSETS.zoom_logo} className="user-image" />
                <p
                  className={
                    setting === "zoom"
                      ? "member-list-name active"
                      : "member-list-name"
                  }
                >
                  Zoom
                </p>
                {setting === "zoom" ? (
                  <AiOutlineRight className="member-name-arrow" />
                ) : null}
              </div>
            </Link>
            <Link
              to={"/settings?setting=files"}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <div
                className="member-list-member"
                style={
                  setting === "files" ? { backgroundColor: "#F4F5FF" } : null
                }
              >
                <img src={ASSETS.files} className="user-image" />
                <p
                  className={
                    setting === "files"
                      ? "member-list-name active"
                      : "member-list-name"
                  }
                >
                  Files
                </p>
                {setting === "files" ? (
                  <AiOutlineRight className="member-name-arrow" />
                ) : null}
              </div>
            </Link>
            {/* {role === 'admin' ? (
              <Link
                to={"/settings?setting=benchmark"}
                style={{ textDecoration: "none", width: "100%" }}
              >
                <div
                  className="member-list-member"
                  style={
                    setting === "benchmark"
                      ? { backgroundColor: "#F4F5FF" }
                      : null
                  }
                >
                  <img src={ASSETS.benchmark} className="user-image" />
                  <p
                    className={
                      setting === "benchmark"
                        ? "member-list-name active"
                        : "member-list-name"
                    }
                  >
                    Benchmark
                  </p>
                  {setting === "benchmark" ? (
                    <AiOutlineRight className="member-name-arrow" />
                  ) : null}
                </div>
              </Link>
            ) : null} */}
          </div>
        </div>
        <div className="analytics-per-peropn-wrapper">{settingPage()}</div>
      </div>
    </div>
  );
}
