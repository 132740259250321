import React, { useState, useEffect } from "react";
import "./Meeting.css";
import "./Analytics.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import ASSETS from "../../assets/Assets";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { BiLeftArrow } from "react-icons/bi";
import AnalyticsPerPerson from "./AnalyticsPerPerson";
import MeetingAnalysis from "./MeetingAnalysis";
import { getUserVideoMetadata, getMeetingListPerUser } from "../../api/Users";
import {
  analyticsPerMeetTranscriptAPI,
  analyticsPerMeetMetricsAPI,
  analyticsPerMeetAPI,
  analyticsPerMeetFeedbackUpdateAPI,
  videoAPI,
} from "../../api/analyticsPerMeet";
import MeetingAnalysisNew from "./MeetingAnalysisNew";

export default function Analytics() {
  const [videoMetadata, setVideoMetadata] = useState([]);
  console.log(videoMetadata);

  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");
  const meeting = params.get("meeting");

  const data = {
    name: "John KellyJohn KellyJohn KellyJohn KellyJohn KellyJohn KellyJohn Kelly",
    user_id: "djchbjdshcbjhcsdjcdcs",
    always_be_closing: 43,
    conversion: 35,
    needs_improvement: 20,
    meetings: videoMetadata,
    // meetings: [
    //   {
    //     meeting_id: "uy2wedv8y32udg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "uy2wed8y32udg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "u2wedv8y32udg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "uy2wedv8y32ug",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "uyedv8y32udg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "uy2wedv8ydg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    //   {
    //     meeting_id: "udv8y32udg",
    //     meeting_name: "jeyrald tyron",
    //     date: "23/03/22",
    //     duration: 1234,
    //     category: "conversion",
    //     highlight_image: ASSETS.person1,
    //   },
    // ],
  };
  // console.log(user)

  const [curentMeet, setCurrentMeet] = useState([{ title: "" }]);
  // console.log(curentMeet);

  // useEffect(() => {
  //   const temp = videoMetadata.filter((e) => {
  //     console.log(e)
  //     return e.video_id === meeting;
  //   })[0].title;
  //   console.log(temp)
  //   setCurrentMeet(temp);
  // }, [meeting]);

  const [searchFilter, setSearchFilter] = useState("");

  const backLink = "/analytics?user=" + user;

  const [meetingData, setMeetingData] = useState({
    download_url: null,
    duration: null,
    feedback: null,
    host_id: null,
    meeting_id: null,
    recording_type: null,
    start_time: null,
    title: null,
    video_id: null,
  });

  // console.log(meetingData);

  const getMeetingsDataHelper = async (user_id) => {
    // let user_id = 'user
    console.log(user_id);
    console.log("fecthing metadata for user -", user_id);
    let response = await getMeetingListPerUser(user_id);
    console.log(response);
    console.log("user video metadata response - ", response.data);
    setVideoMetadata(response.data);
  };

  useEffect(() => {
    getMeetingsDataHelper(user);
    // console.log("meetings.js useeffect ran");
  }, [user]);

  const [feedback, setFeedback] = useState({
    edit: false,
    text: "",
  });

  const getMeetingData = async () => {
    const tempData = await analyticsPerMeetAPI(meeting);
    setMeetingData(tempData.data[0]);

    if (tempData.data[0] !== null && tempData.data[0] !== undefined) {
      setFeedback({ text: tempData.data[0].feedback, edit: false });
    }
  };

  useEffect(() => {
    // getMeetingData();
    setMeetingData({
      download_url: "test",
      duration: 60,
      feedback: "test",
      host_id: "test",
      meeting_id: "test",
      recording_type: "test",
      start_time: "test",
      title: "test",
      video_id: "test",
    });
  }, [meeting]);

  return (
    <div className="analysis-wrapper">
      <div className="center-left-heading">
        <Link to={backLink}>
          <BiLeftArrow
            style={{
              marginRight: "2rem",
              marginTop: "0.2rem",
              "&:hover": {
                background: "black",
              },
            }}
          />
        </Link>
        <p className="member-analysis-heading">
          Title:{" "}
          {videoMetadata.filter((e) => {
            // console.log(e);
            return e.video_id === meeting;
          })[0] !== undefined
            ? videoMetadata.filter((e) => {
                // console.log(e);
                return e.video_id === meeting;
              })[0].title
            : null}
        </p>
      </div>
      <div className="meeting-main-content">
        <div className="member-list-meeting">
          <p
            className="member-list-name active"
            style={{ width: "100%", marginBottom: "0.5rem" }}
          >
            Meetings
          </p>
          <OutlinedInput
            placeholder="Search"
            size="small"
            sx={{ width: "100%", marginBottom: "1rem" }}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
          <div className="member-list-names-list-meeting">
            {videoMetadata
              .filter((e) => {
                return e.title?.includes(searchFilter);
              })
              .map((e) => {
                const link =
                  "/analytics?user=" + user + "&meeting=" + e.video_id;
                return (
                  <Link
                    to={link}
                    style={{ textDecoration: "none", width: "100%" }}
                  >
                    <div
                      className="member-list-member"
                      key={e.user_id}
                      style={
                        meeting === e.video_id
                          ? { backgroundColor: "#F4F5FF" }
                          : null
                      }
                    >
                      <img src={e.highlight_image} className="user-image" />
                      <p
                        className={
                          meeting === e.video_id
                            ? "member-list-name active"
                            : "member-list-name"
                        }
                      >
                        {e.title}
                      </p>
                      {/* {meeting === e.video_id ? (
                        <AiOutlineRight className="member-name-arrow" />
                      ) : null} */}
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
        {/* <MeetingAnalysis
          meetingData={meetingData}
          feedback={feedback}
          setFeedback={setFeedback}
          setMeetingData={setMeetingData}
        /> */}
        <MeetingAnalysisNew />
      </div>
    </div>
  );
}
