import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import "./Upload.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import axios from "axios";
import { Dna } from "react-loader-spinner";
import { validate as uuidValidate } from "uuid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { v4 as uuidv4 } from "uuid";
import { uploadvideoApi } from "../../api/upload";
import SelectAnalysisLevel from "./Dropbox";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import "@aws-amplify/ui-react/styles.css";
import { S3FileUpload } from "./S3FileUpload";
import { readFilesMetadaAPI } from "../../api/upload";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#2A2FA1",
  "&:hover": {
    backgroundColor: "rgba(42, 47, 161, 0.66);",
    color: "white",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "3rem",
}));

export default function Upload() {
  const raw = useSelector((store) => {
    return store.user.user_details;
  });
  const userId = raw !== null ? raw.user_id : null;

  const [video, setVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responsedata, setResponsedata] = useState(null);
  // const [meeting_uuid, set_meeting_uuid] = useState(null);
  const [checkAnalysisLevel, setCheckAnalsisLevel] = useState(false);
  const [userLevelFiles, setUserLevelFiles] = useState({
    buyerPersonaFiles: [],
    productDescriptionFiles: [],
    playbookFiles: [],
    agendaFiles: [],
  });

  const [
    buyerPersonaAnalysisDefaultLevel,
    setBuyerPersonaAnalysisDefaultLevel,
  ] = useState("");
  const [playbookAnalysisDefaultLevel, setPlaybookAnalysisDefaultLevel] =
    useState("");
  const [
    productDescriptionAnalysisDefaultLevel,
    setProdcutDescriptionAnalysisDefaultLevel,
  ] = useState("");
  const [agendaAnalysisDefaultLevel, setAgendaAnalysisDefaultLevel] =
    useState("");

  const [buyerPersonaAnalysisLevel, setBuyerPersonaAnalysisLevel] = useState(
    buyerPersonaAnalysisDefaultLevel
  );
  const [playbookAnalysisLevel, setPlaybookAnalysisLevel] = useState(
    playbookAnalysisDefaultLevel
  );
  const [productDescriptionAnalysisLevel, setProdcutDescriptionAnalysisLevel] =
    useState(productDescriptionAnalysisDefaultLevel);
  const [agendaAnalysisLevel, setAgendaAnalysisLevel] = useState(
    agendaAnalysisDefaultLevel
  );

  const [buyerPersonafiles, setBuyerPersonaFiles] = React.useState({
    files: {},
  });
  const [playbookfiles, setPlaybookFiles] = React.useState({ files: {} });
  const [productDescriptionfiles, setProductDescriptionFiles] = React.useState({
    files: {},
  });
  const [agendafiles, setAgendaFiles] = React.useState({ files: {} });

  //depending on the files set the analysis level to user or default

  const resetStates = () => {
    setVideo(null);
    setVideoId(null);
    setMeetingId(null);
    //setLoading(false);
    //setResponsedata(null);
    setCheckAnalsisLevel(false);
    setBuyerPersonaAnalysisLevel("user");
    setPlaybookAnalysisLevel("user");
    setAgendaAnalysisLevel("user");
    setProdcutDescriptionAnalysisLevel("user");
    setBuyerPersonaFiles({ files: {} });
    setPlaybookFiles({ files: {} });
    setProductDescriptionFiles({ files: {} });
    setAgendaFiles({ files: {} });
    set_title("");
    set_start_time("");
  };

  const checkIfFilesAreUploaded = (files) => {
    //console.log(files);
    if (Object.keys(files["files"]).length === 0) return false;
    //console.log(files);

    for (var key of Object.keys(files["files"])) {
      if (files["files"][key]) {
        if (files["files"][key]["status"] === "success") {
          return true;
        }
      }
    }

    return false;
  };

  const validateAnalysisLevel = () => {
    let buyerPersona = true;
    let playbook = true;
    let productDescription = true;
    let agenda = true;

    if (buyerPersonaAnalysisLevel === "video") {
      buyerPersona = checkIfFilesAreUploaded(buyerPersonafiles);
    }
    // else if (buyerPersonaAnalysisLevel === "user") {
    //   buyerPersona = userLevelFiles.buyerPersonaFiles.length > 0;
    // } else {
    //   buyerPersona = false;
    // }

    if (playbookAnalysisLevel === "video") {
      playbook = checkIfFilesAreUploaded(playbookfiles);
    }
    // else if (playbookAnalysisLevel === "user") {
    //   playbook = userLevelFiles.playbookFiles.length > 0;
    // } else {
    //   playbook = false;
    // }

    if (productDescriptionAnalysisLevel === "video") {
      productDescription = checkIfFilesAreUploaded(productDescriptionfiles);
    }
    // else if (productDescriptionAnalysisLevel === "user") {
    //   productDescription = userLevelFiles.productDescriptionFiles.length > 0;
    // } else {
    //   productDescription = false;
    // }

    if (agendaAnalysisLevel === "video") {
      agenda = checkIfFilesAreUploaded(agendafiles);
    }
    // else if (agendaAnalysisLevel === "user") {
    //   agenda = userLevelFiles.agendaFiles.length > 0;
    // } else {
    //   agenda = false;
    // }

    return buyerPersona && playbook && productDescription && agenda;
  };

  useEffect(() => {
    //Check user level files
    readFilesMetadaAPI(userId, "buyer_persona", "user").then((res) => {
      if (res.data.length > 0) {
        setBuyerPersonaAnalysisDefaultLevel("user");
      } else {
        setBuyerPersonaAnalysisDefaultLevel("default");
      }
      setUserLevelFiles((prevFiles) => {
        return {
          ...prevFiles,
          buyerPersonaFiles: res.data,
        };
      });
    });
    readFilesMetadaAPI(userId, "product_description", "user").then((res) => {
      if (res.data.length > 0) {
        setProdcutDescriptionAnalysisDefaultLevel("user");
      } else {
        setProdcutDescriptionAnalysisDefaultLevel("default");
      }
      setUserLevelFiles((prevFiles) => {
        return {
          ...prevFiles,
          productDescriptionFiles: res.data,
        };
      });
    });
    readFilesMetadaAPI(userId, "playbook", "user").then((res) => {
      if (res.data.length > 0) {
        setPlaybookAnalysisDefaultLevel("user");
      } else {
        setPlaybookAnalysisDefaultLevel("default");
      }
      setUserLevelFiles((prevFiles) => {
        return {
          ...prevFiles,
          playbookFiles: res.data,
        };
      });
    });
    readFilesMetadaAPI(userId, "agenda", "user").then((res) => {
      if (res.data.length > 0) {
        setAgendaAnalysisDefaultLevel("user");
      } else {
        setAgendaAnalysisDefaultLevel("default");
      }
      setUserLevelFiles((prevFiles) => {
        return {
          ...prevFiles,
          agendaFiles: res.data,
        };
      });
    });
  }, []);

  useEffect(() => {
    setBuyerPersonaAnalysisLevel(buyerPersonaAnalysisDefaultLevel);
    setPlaybookAnalysisLevel(playbookAnalysisDefaultLevel);
    setProdcutDescriptionAnalysisLevel(productDescriptionAnalysisDefaultLevel);
    setAgendaAnalysisLevel(agendaAnalysisDefaultLevel);
  }, [
    buyerPersonaAnalysisDefaultLevel,
    productDescriptionAnalysisDefaultLevel,
    playbookAnalysisDefaultLevel,
    agendaAnalysisDefaultLevel,
  ]);

  useEffect(() => {
    //console.log(validateAnalysisLevel());
    setCheckAnalsisLevel(validateAnalysisLevel());
  }, [
    video,
    buyerPersonaAnalysisLevel,
    playbookAnalysisLevel,
    productDescriptionAnalysisLevel,
    agendaAnalysisLevel,
    buyerPersonafiles,
    playbookfiles,
    productDescriptionfiles,
    agendafiles,
  ]);

  const onDrop = useCallback((acceptedFiles) => {
    //console.log(acceptedFiles[0]);

    setVideo(acceptedFiles[0]);
    setVideoId(uuidv4());
    setMeetingId(uuidv4());
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/*": [".mp4", ".mov", ".webm"],
    },
  });

  const org_id = useSelector((e) => {
    //console.log(e);

    return e.user.user_details.organisation_id;
  });

  const user_id = useSelector((e) => {
    return e.user.user_details.user_id;
  });

  const baseStyle = {
    padding: "1rem",
    width: "100%",
    height: "10rem",
    textAlign: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const [title, set_title] = useState(null);
  const [start_time, set_start_time] = useState(null);

  function addZero(str) {
    return str < 10 ? "0" + str : str;
  }

  useEffect(() => {
    if (video == null) {
      return;
    }

    setResponsedata(null);
    var currentdate = new Date();
    var datetime =
      addZero(currentdate.getFullYear()) +
      "-" +
      addZero(currentdate.getMonth() + 1) +
      "-" +
      addZero(currentdate.getDate()) +
      " " +
      addZero(currentdate.getHours()) +
      ":" +
      addZero(currentdate.getMinutes()) +
      ":" +
      addZero(currentdate.getSeconds());

    set_title(video.name.split(".").slice(0, -1).join("."));
    set_start_time(datetime);
  }, [video]);

  const handleVideoUpload = async () => {
    setLoading(true);
    setResponsedata(null);

    const result = await uploadvideoApi(
      org_id,
      user_id,
      title,
      start_time,
      videoId,
      meetingId,
      buyerPersonaAnalysisLevel,
      productDescriptionAnalysisLevel,
      playbookAnalysisLevel,
      agendaAnalysisLevel
    );

    //console.log(result);

    const presignedS3Url = result.data.pre_signed_url;

    //console.log(video.type);

    const axiosResponse = await axios.put(presignedS3Url, video, {
      headers: {
        "Content-Type": video.type,
      },
    });

    //console.log(axiosResponse);

    //clear states
    resetStates();
    setResponsedata(result);
    setLoading(false);
  };

  const handleBuyerPersonaAnalysisLevelChange = (event) => {
    setBuyerPersonaAnalysisLevel(event.target.value);
  };

  const handlePlaybookAnalysisLevelChange = (event) => {
    setPlaybookAnalysisLevel(event.target.value);
  };

  const handleProdcutDescriptionAnalysisLevelChange = (event) => {
    setProdcutDescriptionAnalysisLevel(event.target.value);
  };

  const handleAgendaAnalysisLevelChange = (event) => {
    setAgendaAnalysisLevel(event.target.value);
  };

  return (
    <div className="">
      <p className="member-analysis-heading">Upload</p>

      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ padding: 0 })} />
          {video === null ? (
            isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )
          ) : (
            <p>Video Added</p>
          )}
        </div>

        {video == null ? null : video.name}
      </div>
      <div className="manual-upload-details">
        <span className="member-analysis-heading">Start time:</span>

        <input
          type="text"
          className="input-blocks"
          value={start_time}
          onChange={(e) => set_start_time(e.target.value)}
        />
      </div>
      <div className="manual-upload-details">
        <span className="member-analysis-heading">Title:</span>

        <input
          type="text"
          className="input-blocks"
          value={title}
          onChange={(e) => set_title(e.target.value)}
        />
      </div>

      <div className="manual-upload-extra-files-uploader-container">
        <Accordion disabled={videoId !== null ? false : true}>
          {
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="manual-upload-extra-files-uploader--container-heading">
                Upload Metadata Files
              </div>
            </AccordionSummary>
          }
          <AccordionDetails>
            <div className="manual-upload-analysis-level-parent-container">
              <div className="manual-upload-analysis-level-container">
                Buyer Persona
                <SelectAnalysisLevel
                  analysisLevel={buyerPersonaAnalysisLevel}
                  handleChange={handleBuyerPersonaAnalysisLevelChange}
                  defaultOrUser={buyerPersonaAnalysisDefaultLevel}
                />
              </div>
              {buyerPersonaAnalysisLevel === "video" ? (
                <S3FileUpload
                  files={buyerPersonafiles}
                  setFiles={setBuyerPersonaFiles}
                  level="video"
                  type="buyer_persona"
                  resource_id={videoId}
                />
              ) : (
                <div></div>
              )}
              {/* buyerPersonaAnalysisLevel === "user" ? (
                userLevelFiles.buyerPersonaFiles.length > 0 ? (
                  <div></div>
                ) : (
                  <div>No files found ! Please upload in settings page</div>
                )
              ) : (
                ""
              )} */}
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className="manual-upload-analysis-level-parent-container">
              <div className="manual-upload-analysis-level-container">
                Playbook
                <SelectAnalysisLevel
                  analysisLevel={playbookAnalysisLevel}
                  handleChange={handlePlaybookAnalysisLevelChange}
                  defaultOrUser={playbookAnalysisDefaultLevel}
                />
              </div>
              {playbookAnalysisLevel === "video" ? (
                <S3FileUpload
                  files={playbookfiles}
                  setFiles={setPlaybookFiles}
                  level="video"
                  type="playbook"
                  resource_id={videoId}
                />
              ) : (
                <div></div>
              )}
              {/* playbookAnalysisLevel === "user" ? (
                userLevelFiles.playbookFiles.length > 0 ? (
                  <div></div>
                ) : (
                  <div>No files found ! Please upload in settings page</div>
                )
              ) : (
                ""
              )} */}
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className="manual-upload-analysis-level-parent-container">
              <div className="manual-upload-analysis-level-container">
                Product Description
                <SelectAnalysisLevel
                  analysisLevel={productDescriptionAnalysisLevel}
                  handleChange={handleProdcutDescriptionAnalysisLevelChange}
                  defaultOrUser={productDescriptionAnalysisDefaultLevel}
                />
              </div>
              {productDescriptionAnalysisLevel === "video" ? (
                <S3FileUpload
                  files={productDescriptionfiles}
                  setFiles={setProductDescriptionFiles}
                  level="video"
                  type="product_description"
                  resource_id={videoId}
                />
              ) : (
                <div></div>
              )}
              {/* productDescriptionAnalysisLevel === "user" ? (
                userLevelFiles.productDescriptionFiles.length > 0 ? (
                  <div></div>
                ) : (
                  <div>No files found ! Please upload in settings page</div>
                )
              ) : (
                ""
              )} */}
            </div>
          </AccordionDetails>
          <AccordionDetails>
            <div className="manual-upload-analysis-level-parent-container">
              <div className="manual-upload-analysis-level-container">
                Agenda
                <SelectAnalysisLevel
                  analysisLevel={agendaAnalysisLevel}
                  handleChange={handleAgendaAnalysisLevelChange}
                  defaultOrUser={agendaAnalysisDefaultLevel}
                />
              </div>
              {agendaAnalysisLevel === "video" ? (
                <S3FileUpload
                  files={agendafiles}
                  setFiles={setAgendaFiles}
                  level="video"
                  type="agenda"
                  resource_id={videoId}
                />
              ) : (
                <div></div>
              )}
              {/* agendaAnalysisLevel === "user" ? (
                userLevelFiles.agendaFiles.length > 0 ? (
                  <div></div>
                ) : (
                  <div>No files found ! Please upload in settings page</div>
                )
              ) : (
                ""
              )} */}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="manual-upload-button-container">
        {loading === false ? (
          videoId == null || checkAnalysisLevel === false ? (
            <ColorButton variant="contained" disabled={true}>
              Upload
            </ColorButton>
          ) : (
            <ColorButton
              variant="contained"
              onClick={() => {
                handleVideoUpload();
              }}
            >
              Upload
            </ColorButton>
          )
        ) : (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
      {/* {console.log(responsedata)} */}
      {responsedata !== null ? `${responsedata.data.video_id} uploaded` : null}
    </div>
  );
}
