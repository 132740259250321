import React, { useEffect, useState } from "react";
import "./Profile.css";
import ASSETS from "../../assets/Assets";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  getZoomUserSettings,
  updateZoomSettings,
} from "../../api/ZoomUserSettings";
import { useSelector, useDispatch } from "react-redux";

// const profile_pic = ASSETS.person1;
// // const email = "kjsadk@kdjhf.com";
// const role = "manager";
// const name = "johnd deere";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#4A8CFF",
  "&:hover": {
    backgroundColor: "rgba(74, 140, 255, 0.66)",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "1rem",
}));

export default function Zoom() {
  const [email, setEmail] = useState();
  // const user_id = "fd4e6a12-5ba3-4dc8-ad0e-0e27635bd9fa";
  // const org_id = "test_org";

  const raw = useSelector((store) => {
    return store.user.user_details;
  });

  const org_id = raw !== null ? raw.organisation_id : null;
  const user_id = raw !== null ? raw.user_id : null;

  const [newEmail, setNewEmail] = useState();
  const fetchZoomUserData = async () => {
    let response = await getZoomUserSettings(org_id, user_id);
    console.log("response from zoom - ", response);
    setEmail(response.data[0].zoom_email_id);
    console.log("email - ", email, response.data[0].zoom_email_id);
  };

  const handleClick = async () => {
    let response = await updateZoomSettings(org_id, user_id, email);
    console.log("response for email update - ", response);
  };

  useEffect(() => {
    fetchZoomUserData(org_id, user_id);
  }, [raw]);

  return (
    <div className="settings-other-wrapper">
      <div className="settings-logo-wrapper">
        <img className="settings-logo-main" src={ASSETS.zoom_logo} />
        <img className="settings-logo-text" src={ASSETS.zoom_text_logo} />
      </div>
      <p className="settings-subheading">Email id</p>
      <OutlinedInput
        placeholder="Enter your email"
        size="small"
        value={email}
        onChange={(event) => {
          // console.log("on change  -", event.target.value);
          setEmail(event.target.value);
        }}
        sx={{ width: "400px", marginBottom: "1rem" }}
      />
      {/* <p className="setting-details">
        If you have not applied an API key and secret yet, follow this procedure
        to recieve it.
      </p> */}
      {/* <p className="settings-subheading">API Secret</p>
      <OutlinedInput
        placeholder=""
        size="small"
        sx={{ width: "400px", marginBottom: "1rem" }}
      /> */}
      {/* <p className="setting-details">
        If you have not applied an API key and secret yet, follow this procedure
        to recieve it.
      </p> */}
      <br />
      <ColorButton variant="contained" onClick={handleClick}>
        Save
      </ColorButton>
    </div>
  );
}
