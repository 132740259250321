import iby_logo from "./iby_logo.png";
import logo from "./logo.svg";
import dashboard_morning from "./dashboard_morning.png";
import always_be_closing from "./always_be_closing.png";
import conversion from "./conversion.png";
import needs_improvement from "./needs_improvement.png";
import person1 from "./person1.png";
import user from "./user.png";
import coin from "./coin.png";
import zoom_logo from "./zoom_logo.png";
import google_meet_logo from "./google_meet_logo.png";
import ms_teams_logo from "./ms_teams_logo.png";
import Google_Meet_text_logo from "./Google_Meet_text_logo.png";
import zoom_text_logo from "./zoom_text_logo.png";
import ms_teams_text_logo from "./ms_teams_text_logo.png";
import promotion_logo from "./promotion_logo.png";
import loading from "./loading.gif";
import benchmark from "./benchmark.png";
import files from "./folder.png";

const ASSETS = {
  iby_logo,
  logo,
  dashboard_morning,
  always_be_closing,
  conversion,
  needs_improvement,
  person1,
  user,
  coin,
  zoom_logo,
  google_meet_logo,
  ms_teams_logo,
  Google_Meet_text_logo,
  zoom_text_logo,
  ms_teams_text_logo,
  promotion_logo,
  loading,
  benchmark,
  files,
};

export default ASSETS;
