import React from "react";
import "./Profile.css";
import ASSETS from "../../assets/Assets";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const profile_pic = ASSETS.person1;
const email = "kjsadk@kdjhf.com";
const role = "manager";
const name = "johnd deere";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#474EB7",
  "&:hover": {
    backgroundColor: "#7B83EB",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "1rem",
}));

export default function MSTeams() {
  return (
    <div className="settings-other-wrapper">
      <div className="settings-logo-wrapper">
        <img className="settings-logo-main" src={ASSETS.ms_teams_logo} />
        <img className="settings-logo-text" src={ASSETS.ms_teams_text_logo} />
      </div>
      <p className="settings-subheading">API Key</p>
      <OutlinedInput
        placeholder=""
        size="small"
        sx={{ width: "400px", marginBottom: "1rem" }}
      />
      {/* <p className="setting-details">
        If you have not applied an API key and secret yet, follow this procedure
        to recieve it.
      </p> */}
      <p className="settings-subheading">API Secret</p>
      <OutlinedInput
        placeholder=""
        size="small"
        sx={{ width: "400px", marginBottom: "1rem" }}
      />
      {/* <p className="setting-details">
        If you have not applied an API key and secret yet, follow this procedure
        to recieve it.
      </p> */}
      <br />
      <ColorButton variant="contained">Save</ColorButton>
    </div>
  );
}
