import React, { useEffect, useState } from "react";
import "./Summary.css";
import Checkbox from "@mui/material/Checkbox";
import { createActionItemAPI, getActionItemsAPI, setMetricLabelAPI, v2AnalyisAPI } from "../../../api/analyticsPerMeet";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoCloseSharp } from "react-icons/io5";
import { FaCheckCircle,FaRegCheckCircle  } from "react-icons/fa";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import TextField from '@mui/material/TextField';


const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Summary({
  generalActionableSuggestionInput,
  generalOverallSummary,
  totalScore,
  seekToTimestamp, meeting
}) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedLabel,setSelectedLabel] = useState("client")
  const [enteredText, setEnteredText] = useState("")
  const [buttonText, setButtonText] = useState("Save")

  // const [generalActionableSuggestion, setGeneralActionableSuggestion] = useState(generalActionableSuggestionInput)
  const [generalActionableSuggestion, setGeneralActionableSuggestion] = useState([])

  const refreshData = async () => {
    
    // let generalActionableSuggestionResponse = await v2AnalyisAPI(
    //   meeting,
    //   "general",
    //   "action_items"
    // );
    let generalActionableSuggestionResponse = await getActionItemsAPI(meeting);
    console.log("refreshing next steps data")
    console.log("action items fetched - ",generalActionableSuggestionResponse.data)
    setGeneralActionableSuggestion(generalActionableSuggestionResponse.data);
  }


  const setMetricLabel = async(metric_id, set) => {
    let response = await setMetricLabelAPI(metric_id, set)
    
    // console.log("response on calling set metric api - ", response)
    if(response.status === 200)
      // window.location.reload()
      refreshData()
  }

  const handleAddItem = async() => {
    try{
    let response = await createActionItemAPI(meeting,selectedLabel,enteredText,"next_step","No")
    if(response.status === 200){
      setButtonText("Done")
      handleClose()
      refreshData()
    }
    else{
      setButtonText("Error")
    }
  }catch(err){
    setButtonText("Error!")
  }
  }

useEffect(()=>{refreshData()},[meeting])
  


  console.log("input props  - ",{
    generalActionableSuggestion,
    generalOverallSummary,
    totalScore,
    seekToTimestamp,
  })

  

  return (
    <div className="summary-wrapper">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <div className="modal-root">

        <div className="modal-div">
          <div className="modal-topbar">
            <span className="heading-text">Add Steps</span>
            <IoCloseSharp size={32} color="#575757" onClick={handleClose}/>

          </div>
          <div className="modal-content">
          <span className="info-text">Step for:</span>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedLabel}
          label="Step for"
          onChange={(event)=>{setSelectedLabel(event.target.value)}}
        >
          <MenuItem value={"client"}>Client</MenuItem>
          <MenuItem value={"sales-person"}>Sales-person</MenuItem>
          <MenuItem value={"misc"}>Others</MenuItem>
        </Select>
        <span className="info-text">Add Step:</span>
        <TextField id="outlined-basic" label="Enter text" variant="outlined"
        onChange={(event)=>{setEnteredText(event.target.value)}}
        value={enteredText} 

        />
        <div/>
        <div/>
        <div className="pink-button-big"
        onClick={handleAddItem}
        >{buttonText}</div>
          </div>
        </div>
      </div>
      </Modal>
      <div className="summary-summary-wrapper">
      <span className="heading-text">Summary</span>

        {generalOverallSummary.map((data, idx) => {
          return (
            <div className="touchpoint">
              <span  className="heading-text-small">
                
              {data.metric_label}
              </span>
              : <br /> {data.transcript} <hr />
              <span className="info-text">

              {data.metric_value}
              </span>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="summary-summary-wrapper">
      <div className="summary-tasks-container">
        <span className="heading-text">Next steps</span>
        <span>Client's Tasks</span>
        {generalActionableSuggestion.filter((e)=>e.item_label === "client").map((e) => {
          return (
            <div>
              <Checkbox
              icon={<FaRegCheckCircle size={18}/>}
              checkedIcon={<FaCheckCircle size={18} color="#2a2fa1"/>}
                {...label}
                checked={e.item_status==="Yes"}
                sx={{
                  color: "default",
                  "&.Mui-checked": {
                    color: "default",
                  },
                }}
                onClick={() => {
                    if(e.item_status === "Yes")
                      setMetricLabel(e.item_id,"No")
                    else if(e.item_status === "No")
                      setMetricLabel(e.item_id,"Yes")
                    else{
                      setMetricLabel(e.item_id,"Yes")
                      console.log("No action for checkbox was taken")}
                  }}
              />
              <span className="info-text">{e.Item_value}</span>
            </div>
          );
        })}

        <span>SRD's Tasks</span>
        {generalActionableSuggestion.filter((e)=>e.item_label === "sales-person").map((e) => {
          return (
            <div>
              <Checkbox
                {...label}
                icon={<FaRegCheckCircle size={18}/>}
                checkedIcon={<FaCheckCircle size={18} color="#2a2fa1"/>}
                checked={e.item_status === "Yes"}
                sx={{
                  color: "black[500]",
                  "&.Mui-checked": {
                    color: "black[500]",
                  },
                }}
                onClick={() => {
                    if(e.item_status === "Yes")
                      setMetricLabel(e.item_id,"No")
                    else if(e.metric_status === "No")
                      setMetricLabel(e.item_id,"Yes")
                    else{
                      setMetricLabel(e.item_id,"Yes")
                      console.log("No action for checkbox was taken")}
                  }}
              />
              <span className="info-text">{e.Item_value}</span>
            </div>
          );
        })}
        <span>Misc Tasks</span>
        {generalActionableSuggestion.filter((e)=>e.item_label !== "sales-person" && e.item_label !== "client").map((e) => {
          return (
            <div>
              <Checkbox
              icon={<FaRegCheckCircle size={18} />}
              checkedIcon={<FaCheckCircle size={18} color="#2a2fa1"/>}
                {...label}
                checked={e.item_status === "Yes"}
                sx={{
                  color: "black[500]",
                  "&.Mui-checked": {
                    color: "black[500]",
                  },
                }}
                onClick={() => {
                    if(e.item_status === "Yes")
                      setMetricLabel(e.item_id,"No")
                    else if(e.item_status === "No")
                      setMetricLabel(e.item_id,"Yes")
                    else{
                      setMetricLabel(e.item_id,"Yes")
                      console.log("No action for checkbox was taken")}
                  }}
              />
              <span className="info-text">{e.Item_value}</span>
            </div>
          );
        })}
        </div>
        <div className="summary-add-steps-container"
        onClick={handleOpen}
        >
          <span className="blue-button">Add Steps</span>
        </div>
      </div>
    </div>
  );
}
