import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
// import faker from "faker";
import { Line } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const color = ["#969AFF", "#F42D77"];

// export const options = {
//   scales: {
//     y: {
//       beginAtZero: true,
//       ticks: {
//         stepSize: 1,
//       },
//       display: false,
//     },
//   },
//   plugins: {
//     legend: {
//       position: "top",
//       display: false,
//     },
//     title: {
//       display: false,
//     },
//   },
// };

export default function RallyChart({ lineData }) {
  // console.log(lineData);

  // const [rawData, setRawData] = useState([]);
  // const [yLabels, setYLabels] = useState([])

  // useEffect(() => {
  //   const uniqueValues = [...new Set(lineData.map((e) => e.participant_name))];
  //   setRawData(
  //     uniqueValues.map((e) => {
  //       console.log(e)
  //       return {
  //         label: e,
  //         data: lineData
  //           .filter((v) => {
  //             return v.value === e;
  //           })
  //           .map((q) => {
  //             var tempo = uniqueValues.indexOf(q.value) + 1;
  //             return {
  //               x: q.start_time,
  //               y: tempo,
  //             };
  //           }),
  //       };
  //     })
  //   );
  // }, [lineData]);

  // const data = {
  //   datasets: rawData,
  // };

  // const plotData = lineData.map((e) => {
  //   return e.value;
  // });

  const secondsToHms = (d) => {
    // console.log(d)
    d = Number(d);
    // console.log(d)
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const [plotData, setPlotData] = useState([]);
  const [Chartlabel, setLabel] = useState([]);
  const [uniqueValues, setuniqueValues] = useState([]);
  // console.log(plotData);
  // console.log(label);
  // console.log(uniqueValues);

  useEffect(() => {
    setuniqueValues([
      ...new Set(
        lineData.map((e) => e.participant_name.replace("Audio only - ", ""))
      ),
    ]);
  }, []);

  useEffect(() => {
    setLabel(() => {
      var temp = lineData.map((e) => {
        return e.start_time;
      });
      temp.push(
        ...lineData.map((e) => {
          return e.end_time;
        })
      );
      return [
        ...new Set(
          temp.sort(function (a, b) {
            return a - b;
          })
        ),
      ];
    });
  }, []);

  useEffect(() => {
    setPlotData(
      lineData.map((e) => {
        return {
          label: e.participant_name.replace("Audio only - ", ""),
          data: Chartlabel.map((f) => {
            if (f === e.start_time || f === e.end_time) {
              return uniqueValues.indexOf(
                e.participant_name.replace("Audio only - ", "")
              );
            }
            return null;
          }),
          borderColor:
            color[
              uniqueValues.indexOf(
                e.participant_name.replace("Audio only - ", "")
              )
            ],
          backgroundColor: color[
              uniqueValues.indexOf(
                e.participant_name.replace("Audio only - ", "")
              )
            ],
        };
      })
    );
  }, [Chartlabel, uniqueValues]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return secondsToHms(tooltipItem[0].label);
          },
          label: function (tooltipItem) {
            console.log(tooltipItem.dataset.label);
            return tooltipItem.dataset.label;
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        min: -0.5,
        ticks: {
          stepSize: 1,
          callback: function (label, index, labels) {
            return uniqueValues[label];
          },
        },
      },
      x: {
        min: 0,
        ticks: {
          callback: function (label, index, labels) {
            return secondsToHms(Chartlabel[label]);
          },
        },
      },
    },
  };

  const data = {
    labels: Chartlabel,
    datasets: plotData,
  };

  // return <Scatter options={options} data={data} />;
  return <Line options={options} data={data} />;
}
