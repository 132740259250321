import React, { useEffect, useState } from "react";
import "./Profile.css";
import ASSETS from "../../assets/Assets";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { readBenchmarkAPI, updateBenchmarkAPI } from "../../api/setting";

const profile_pic = ASSETS.person1;
const email = "kjsadk@kdjhf.com";
const role = "manager";
const name = "johnd deere";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F42D77",
  "&:hover": {
    backgroundColor: "rgba(244, 45, 119, 0.6)",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "0rem",
}));

const ColorButtonBlue = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#4A8CFF",
  "&:hover": {
    backgroundColor: "rgba(74, 140, 255, 0.66)",
  },
  width: "160px",
  height: "2.5rem",
  marginTop: "0rem",
  marginLeft: "1rem",
}));

export default function Benchmark() {
  const [mirroring, setMirroring] = useState("");
  const [confidence, setConfidence] = useState("");
  const [appropriate_wording_from_sales, setAppropriate_wording_from_sales] =
    useState("");
  const [appropriate_wording_from_client, setAppropriate_wording_from_client] =
    useState("");
  const [speach_rally, setSpeach_rally] = useState("");
  const [qns_and_doubts, setQns_and_doubts] = useState("");

  const organisation_id = useSelector((store) => {
    // console.log(store);
    if (store.user.user_details === null) {
      return "";
    }
    return store.user.user_details.organisation_id;
  });

  const [benchmarkScore, setBenchMarkScore] = useState([]);

  const getMetricDetails = (metric_type) => {
    if (benchmarkScore === []) {
      return 0;
    }
    const temp = benchmarkScore.filter((e) => {
      return e.metric_type === metric_type;
    })[0];
    console.log(temp);
    if (temp === undefined) {
      return 0;
    }
    if (temp.org_score !== null) {
      return temp.org_score;
    }
    return temp.score;
  };

  const updateBenchmarkHelper = async (metric_type) => {
    var score = eval(metric_type);
    // console.log(metric_type);
    // console.log(score);

    const result = await updateBenchmarkAPI(
      organisation_id,
      metric_type,
      score
    );

    // console.log(result);
  };

  useEffect(() => {
    // console.log(getMetricDetails("mirroring"));
    setMirroring(getMetricDetails("mirroring"));
    setConfidence(getMetricDetails("confidence"));
    setAppropriate_wording_from_sales(
      getMetricDetails("appropriate_wording_from_sales")
    );
    setAppropriate_wording_from_client(
      getMetricDetails("appropriate_wording_from_client")
    );
    setSpeach_rally(getMetricDetails("speach_rally"));
    setQns_and_doubts(getMetricDetails("qns_and_doubts"));
  }, [benchmarkScore]);

  const fetchBenchmarkHelper = async () => {
    const result = await readBenchmarkAPI(organisation_id);
    // console.log(result.data);
    setBenchMarkScore(result.data);
  };

  useEffect(() => {
    fetchBenchmarkHelper();
  }, []);

  const role = useSelector((store) => {
    // console.log(store);
    if (store.user.user_details === null) {
      return "staff";
    }
    return store.user.user_details.role;
  });

  if (role === "admin") {
    return (
      <div className="settings-other-wrapper setting-scroll">
        <div className="settings-logo-wrapper">
          <img className="settings-logo-main" src={ASSETS.benchmark} />
          {/* <img className="settings-logo-text" src={ASSETS.zoom_text_logo} /> */}
          <p className="setting-heading">Benchmark</p>
        </div>
        <div className="benchmark-wrapper">
          <div>
            <p className="settings-subheading">Mirroring</p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={mirroring}
              onChange={(e) => {
                setMirroring(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("mirroring");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
            <p className="settings-subheading">Confidence</p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={confidence}
              onChange={(e) => {
                setConfidence(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("confidence");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
            <p className="settings-subheading">
              Appropriate wording from salesperson
            </p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={appropriate_wording_from_sales}
              onChange={(e) => {
                setAppropriate_wording_from_sales(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("appropriate_wording_from_sales");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
          </div>
          <div className="margin-left-10">
            <p className="settings-subheading">Appropriate words from client</p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={appropriate_wording_from_client}
              onChange={(e) => {
                setAppropriate_wording_from_client(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("appropriate_wording_from_client");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
            <p className="settings-subheading">Speach rally</p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={speach_rally}
              onChange={(e) => {
                setSpeach_rally(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("speach_rally");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
            <p className="settings-subheading">
              Appropriate Answers to questions and doubts
            </p>
            <OutlinedInput
              type="number"
              placeholder=""
              size="small"
              sx={{ width: "400px", marginBottom: "1rem" }}
              value={qns_and_doubts}
              onChange={(e) => {
                setQns_and_doubts(e.target.value);
              }}
            />
            <br />
            <ColorButton
              onClick={() => {
                updateBenchmarkHelper("qns_and_doubts");
              }}
              variant="contained"
            >
              Save
            </ColorButton>
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Access Denied</p>;
  }
}
