import axios from "axios";
import util from "./util";

export async function getZoomUserSettings(organisation_id, user_id) {
  // const apiName = "GetUsersAPI";
  const baseURL = util.baseURL;
  const path = "/zoom_user_settings";

  const parameters =
    "?organisation_id=" + organisation_id + "&user_id=" + user_id;
  const data = axios.get(`${baseURL + path + parameters}`);
  // return await API.get(apiName, path);
  return data;
}

export async function updateZoomSettings(organisation_id, user_id, email_id) {
  const baseURL = util.baseURL;
  const path = "/zoom_user_settings";

  const params = {
    organisation_id: organisation_id,
    user_id: user_id,
    zoom_email_id: email_id,
  };

  const data = await axios.post(`${baseURL + path}`, params);
  // return await API.get(apiName, path);
  return data;
}
